import React from 'react'
import { Greetings } from 'aws-amplify-react'
import { NavItem } from 'aws-amplify-react'

import { Link } from 'react-router-dom'

import '@amzn/awsui-components-react/index.css';

import { Button } from '@amzn/awsui-components-react';

var floatRightStyle = {
    float: 'right', marginTop: '30px'
  };

var signOutStyle = {
    marginRight: '20px'
}

export default class AmbassadorGreetings extends Greetings {
   

    noGreeting() {
        return(<div></div>)
    }

    someGreeting(theme) {
        //<NavItem theme={theme}>{"Hello Kitty"}</NavItem>
        return (
            <span style={signOutStyle}>
                 {
                 (this.props.userProfile!==undefined && this.props.userProfile!==null) ?
                 <span>
                     
                     {this.props.userProfile.first_name} {this.props.userProfile.last_name} &nbsp;
                     <Link to={"/viewProfile/" + this.props.userProfile.cognito_user}><Button text='Profile'></Button></Link>
                    </span>
                 :
                 <Link to={"/editProfile"}><Button text='Edit Profile'/></Link>
                 } &nbsp;
                {this.renderSignOutButton(theme)}
            </span>
        );
    }

    render() {
        const authState  = this.props.authState || this.state.authState;
        const signedIn = (authState === 'signedIn');

        const theme = this.props.theme;
       // const greeting = signedIn? this.userGreetings(theme) : this.noGreeting();

        const greeting = signedIn? this.someGreeting(theme) : this.noGreeting();

        if (!greeting) { return null; }

        return (
            <div>
                    <NavItem theme={theme}><img src="/cb_logo.png"  alt="" height="60px"/></NavItem>
                    <div style={floatRightStyle}>
                        {greeting}
                    </div>
             </div>
        );
    }
}