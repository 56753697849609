import React, { Component } from 'react';
import { API, graphqlOperation } from 'aws-amplify'
import { Auth } from 'aws-amplify'

import {getExplorerProfile} from "../graphql/queries"

import * as JWT from 'jwt-decode';


import { Link } from 'react-router-dom'

import '@amzn/awsui-components-react/index.css';

import {
  AppLayout,
  Button,
  ColumnLayout,
  Icon,
  Spinner,Flash
} from '@amzn/awsui-components-react';
import { appLayoutLabels } from '../common/labels';
import '../styles/form.scss';


export const ProfileDetails = ({ profile , adminView , certifications}) => (
    <ColumnLayout columns={3} variant='text-grid'>
      <div data-awsui-column-layout-root='true'>
        <div>
          <div className='awsui-util-spacing-v-s'>
            <div>
              <div className='awsui-util-label'>Name

            {/* removed the user photo}
              <div>
               {!profile.headshot ? 
               <img src="/Portrait_Placeholder.png" style={headshotStyle} alt=""></img> : 
              <img src={profile.headshot} style={headshotStyle}  alt=""></img>
              }
              </div>
            */}

              </div>
              <div>{profile.first_name} {profile.last_name}</div>
            </div>
            <div>
              <div className='awsui-util-label'>Email</div>
              <div>{profile.email}</div>
            </div>
            <div>
              <div className='awsui-util-label'>Country</div>
              <div>
               {profile.country}
              </div>
            </div>
            <div> {/* // Hiding Profile section
              <div className='awsui-util-label'>Region</div>
              <div>
               {profile.region}
            </div> */}
            </div>
  

          </div>
        </div>
        <div>

       

          <div className='awsui-util-spacing-v-s'>

          <div>
            <div className='awsui-util-label'>Program Status</div>
            <div>
            {"ACTIVE MEMBER" === profile.program_status ? 
              <span className="awsui-util-status-positive"><Icon name="status-positive" /> ACTIVE MEMBER</span>
              : 
              <span className="awsui-util-status-negative">{profile.program_status}</span>
            }
            </div>
          </div>

          {
            (profile.program_status==="ADMINISTRATOR") ?
            <span>

            <div>
              <p/>
              <div className='awsui-util-label'><h4>Administration Status</h4></div>
            </div>
       
           
            <div>
              <div className='awsui-util-label'>Legal Form</div>
              <div>
              {!profile.legal_form ? "-" : profile.legal_form}<p/>
              </div>
            </div>
            <div>
              <div className='awsui-util-label'>NDA URL</div>
              <div>
               {!profile.link_to_nda ? "-" : profile.link_to_nda}<p/>
              </div>
            </div>
   
            <div>
              <div className='awsui-util-label'>Public Sector</div>
              <div>
              {!profile.public_sector ? "-" : profile.public_sector}<p/>
              </div>
            </div>
            </span>
            :
            null
          }
          </div>
        </div>

      </div>
    </ColumnLayout>
  );

export const ThisProfile = ({ profile, ready , adminView , certifications}) => {
    return (
        <div>
         <span>
          <Link to='/myActivities'><Button text='My Activities'/></Link>
          &nbsp; 
          <Link to='/allActivities'><Button text='All Activities'/></Link>
          &nbsp;
          <Link to={"/editProfile"}><Button text='Edit Profile'/></Link>

          <p/>
          </span>   
        <div className='awsui-util-container'>
            
        <div className='awsui-util-container-header'>
            <h2>Profile Details</h2>
        </div>
            {(ready===true)?
            <ProfileDetails profile={profile} ready={ready} adminView={adminView} certifications={certifications}/>:   <Spinner></Spinner>
            }
        </div>
       
      </div>

    );
  };

class ViewProfile extends Component {
   constructor(props){
     super(props);

     this.state = { 
        profile: {cognito_user : this.props.match.params.id , first_name: "" , last_name: "" , country: "USA" , region: "NA"},
        flash_message: null,
        ready: false,
        currentUser: {administrator: false},
        certifications: []
      }
   }

  componentDidMount(){
    if (this.props.location.state!==undefined){
      this.setState({ flash_message: <Flash dismissible={true} type="success" content={this.props.location.state.flash_message} />})
    }

    let parent = this;

    Auth.currentAuthenticatedUser()
    .then(user => {
      var token = JWT(user.signInUserSession.accessToken.jwtToken);
      var isAdministrator = false;
      if (token['cognito:groups']!==undefined && token['cognito:groups'][0]==="APNAmbassadorsAdministrators")
      {isAdministrator=true;}
      user.attributes.administrator=isAdministrator;
      var profile = this.state.profile;
      profile.email=user.attributes.email;
      parent.setState({ currentUser: user.attributes, profile: profile});
      this.getProfileForUser(this.props.match.params.id, profile.email);
      
    }).catch(err => console.log(err)) // end authentication
   
   
  } // end will mount


  getProfileForUser(id, email){
    // fetch contributions for this user
    API.graphql(graphqlOperation(getExplorerProfile,{"cognito_user":id}))
    .then(res => {
      if (res.data.getExplorerProfile!==null) {
        let storedProfile = res.data.getExplorerProfile;
        storedProfile.email = email;
        this.setState({ profile: storedProfile , ready: true})
      } else {
        this.setState({ ready: true })
      }
    }).catch(error => {
      console.log("Couldn't establish ambassador ", error);
      this.setState({ error: "Unable to load profile." , ready: true})
    });

  }
  

  render(){

    return (
      <div className='awsui'>

        <AppLayout
        contentType='table'  
        notifications={this.state.flash_message}
  
        content={<ThisProfile profile={this.state.profile} ready={this.state.ready} adminView={this.state.currentUser.administrator} certifications={this.state.certifications}/>
        }
        navigationHide
        toolsHide
        labels={appLayoutLabels}
        /> 

      </div>

    );
  }

}

export default ViewProfile;
