import React from 'react';
import { Icon } from '@amzn/awsui-components-react';
function validURL(str) {
  var isValidUrl = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.​\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[​6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1​,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00​a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u​00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/i;
  return isValidUrl.test(str);
}

function getItemUrl(item){
  if (validURL(item.link)){
    return (<a href={item.link} target="_blank" rel="noopener noreferrer"><Icon variant="link" name="external"></Icon>
    </a>)
  }
  return (<span> - </span>)
}



export const COLUMN_DEFINITIONS = [
  
{
  id: 'date',
  header: () => 'Date',
  cell: item => item.date_completed,
  minWidth: '100px',
  allowLineWrap: true
},
  {
    id: 'explorer',
    header: () => 'User Name',
    cell: item => <span>{item.name}</span>,
    minWidth: '150px',
    allowLineWrap: true
 },
 {
  id: 'output_type',
  header: () => 'Type',
  cell: item => item.output_type,
  minWidth: '100px',
  allowLineWrap: true
},
{
  id: 'category',
  header: () => 'Category',
  cell: item => item.category,
  minWidth: '100px',
  allowLineWrap: true
},
  {
    id: 'description',
    header: () => 'Description',
    cell: item => item.description,
    minWidth: '200px',
    allowLineWrap: false
  },
  {
    id: 'Link',
    header: () => 'Link',
    cell: item => getItemUrl(item),
    minWidth: '100px',
    allowLineWrap: true
  }
];
export const SORTABLE_COLUMNS = [ { id: 'votes', field: 'total_votes' }];
/*
export const SORTABLE_COLUMNS = [
  { id: 'votes', field: 'total_votes' },
  { id: 'date', field: 'date_completed' },
  { id: 'created', field: 'createdAt' },
  { id: 'output_type', field: 'output_type' },
  { id: 'sslCertificate', field: 'sslCertificate' },
  { id: 'origin', field: 'origin' },
  { id: 'status', field: 'status' },
  { id: 'state', field: 'state' },
  { id: 'logging', field: 'logging' }
];*/

export const CONTENT_SELECTOR_OPTIONS = [
  {
    label: 'Main distribution properties',
    options: [
      { id: 'id', label: 'Distribution ID', editable: false, visible: true },
      { id: 'domainName', label: 'Domain name', editable: true, visible: true },
      { id: 'deliveryMethod', label: 'Delivery method', editable: true, visible: true },
      { id: 'priceClass', label: 'Price class', editable: true, visible: false },
      { id: 'sslCertificate', label: 'SSL certificate', editable: true, visible: true },
      { id: 'origin', label: 'Origin', editable: true, visible: false },
      { id: 'status', label: 'Status', editable: true, visible: true },
      { id: 'state', label: 'State', editable: true, visible: true },
      { id: 'logging', label: 'Logging', editable: true, visible: false }
    ]
  }
];

export const PAGE_SELECTOR_OPTIONS = [
  { value: 10, label: '10 Distributions' },
  { value: 30, label: '30 Distributions' },
  { value: 50, label: '50 Distributions' }
];

export const PROPERTY_FILTERING_OPTIONS = [
  { propertyLabel: 'Domain name', propertyKey: 'domainName', groupValuesLabel: 'Domain name values', values: [] },
  { propertyLabel: 'Delivery method', propertyKey: 'deliveryMethod', groupValuesLabel: 'Delivery method values', values: [] },
  { propertyLabel: 'Price class', propertyKey: 'priceClass', groupValuesLabel: 'Price class values', values: [] },
  { propertyLabel: 'Origin', propertyKey: 'origin', groupValuesLabel: 'Origin values', values: [] },
  { propertyLabel: 'Status', propertyKey: 'status', groupValuesLabel: 'Status values', values: [] },
  { propertyLabel: 'State', propertyKey: 'state', groupValuesLabel: 'State values', values: [] },
  { propertyLabel: 'Logging', propertyKey: 'logging', groupValuesLabel: 'Logging values', values: [] },
  {
    propertyLabel: 'SSL certificate',
    propertyKey: 'sslCertificate',
    groupValuesLabel: 'SSL certificate values',
    values: []
  }
];

export const CUSTOM_PREFERENCE_OPTIONS = [{ value: 'table', label: 'Table' }, { value: 'cards', label: 'Cards' }];

export const PROPERTY_FILTERING_I18N_CONSTANTS = {
  placeholder: 'Filter distributions by property or value',
  operationAndText: 'and',
  operationNotAndText: 'and not',
  operationOrText: 'or',
  operationNotOrText: 'or not',
  clearFiltersText: 'Clear filters',
  groupPropertiesText: 'Properties',
  groupValuesText: 'Values',
  filteringEmpty: 'No results found',
  filteringLabel: 'Filter distributions',
  filteringStatusText: 'Loading'
};