import React from 'react';
import { Link } from 'react-router-dom'
import { API, graphqlOperation } from 'aws-amplify'
import  {categoryTypes} from './ActivitiesCategoryReference' 
import  {contributionTypes} from './ActivitiesTypeReference' 

import {updateActivity} from '../graphql/mutations'

import {
    COLUMN_DEFINITIONS,
    CONTENT_SELECTOR_OPTIONS,
    SORTABLE_COLUMNS,
  } from './table-config-all';
  import { paginationLabels  } from '../common/labels';
  import {
    Button,
    Table,
    TableFiltering,
    TablePagination,
    TableSorting,Flash,Modal,Textarea,Select
  } from '@amzn/awsui-components-react';
  import { getFilterCounterText } from '../common/tableCounterStrings';
  import '../styles/table.scss';
  import { createPropertyStorage } from '../common/createPropertyStorage';
  import * as localStorage from '../common/localStorage';
  import { mapWithColumnDefinitionIds, addToColumnDefinitions } from '../common/columnDefinitionsHelper';

  var floatRightStyle = {
    float: 'right'
  };

  const widthsStorage = createPropertyStorage('React-Table-Widths', localStorage);
  const saveWidths = e => widthsStorage.save(mapWithColumnDefinitionIds(COLUMN_DEFINITIONS, 'width', e.detail.widths));
  const loadWidths = () =>
    widthsStorage.load().then(columns => addToColumnDefinitions(COLUMN_DEFINITIONS, 'width', columns));
  
    export const TableHeader = ({ resultSize, selectedCategory, selectedDistributions, counter, updateTools , watcher , table, disableAdd, reject_reason}) => {
        const modifyContributionStatus = async (payload) => {
          await API.graphql(graphqlOperation(updateActivity, payload))
          .then(res =>  {

              if (res.data.updateActivity!=null){
                  console.log("contribution successfully updated: ", res );
                  // create a flash message.
                  // set the status on screen 

                  table.props.distributions.find(x => x.id === selectedDistributions[0].id).status=res.data.updateActivity.status;
                  table.forceUpdate();
                  var newFlash = <Flash dismissible={true} type="success" visible={true} content={"Status modified successfully to " + res.data.updateActivity.status}/>;
                  watcher.setFlashMessage(newFlash);


              }else{

                  watcher.setFlashMessage(<Flash dismissible={true} type="error"  visible={true} content={"Something went wrong. Try again later."} />);
              }
            
          })
          .catch(error => console.error("Something went wrong updating contribution: ", error));
        }

        if(reject_reason!==undefined && reject_reason!=='' && table.state.cancelReject!==true){
          console.log("Reason for rejection is: " + reject_reason);
          // do the rejection
          console.log("Doing one rejection");

          var payload={
            "input": {
              "id": selectedDistributions[0].id,"status": "REJECTED", reject_reason: reject_reason
            }
          }

          console.log("payload", payload);

          modifyContributionStatus(payload);

          table.setState({reject_reason:''});
        }
     

        return (
          <div className='awsui-util-action-stripe'>
            <div className='awsui-util-action-stripe-title'>
              <h1 className='awsui-util-font-size-3 awsui-util-d-ib'>
                <strong>Activities </strong> {resultSize} in  {selectedCategory}
              </h1> 
            </div>
            <div className='awsui-util-action-stripe-group'>
              
                {/*<span>
                <Button  variant="link" text='Selected Actions:'/>
                <Button disabled={!isOnlyOneSelected || selectedDistributions[0].status!=="APPROVED" || (selectedDistributions[0].votes!==null && selectedDistributions[0].votes.includes(watcher.state.currentUser.sub))} text='UPVOTE' onClick={(e) => {upVote(e)}}/>
                </span>*/}
                &nbsp; &nbsp;
                { (watcher.state.currentUser.administrator === true) ?
                <span>
                {/*<Button text='Delete' disabled={selectedDistributions.length === 0} onClick={handleDelete}/>
                <Button  variant="link" text='Modify:'/>
                <Button disabled={!isOnlyOneSelected || selectedDistributions[0].status==="APPROVED"} text='APPROVED' onClick={(e) => {modifyStatus(e, "APPROVED")}}/>
                <Button disabled={!isOnlyOneSelected || selectedDistributions[0].status==="REJECTED"} text='REJECTED' onClick={(e) => {handleReject(e)}}/>
                <Button disabled={!isOnlyOneSelected || selectedDistributions[0].status==="PENDING"} text='PENDING'  onClick={(e) => {modifyStatus(e, "PENDING")}}/>
                */}</span> : 
                null
               }

            </div>

          </div>
        );
      };
  
class AllActivitiesTable extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        columnDefinitions: COLUMN_DEFINITIONS,
        selectedCategory: props.selectedCategory,
        selectedType: props.selectedType,
        selectedDistributions: [],
        filteringText: '',
        pageSize: 30,
        contentSelectorOptions: CONTENT_SELECTOR_OPTIONS,
        wrapLines: false,
        isAdmin: false
      };
      this.onPaginationChange = this.onPaginationChange.bind(this);
      this.handleCancel = this.handleCancel.bind(this);
      this.handleOk = this.handleOk.bind(this);
      this.handleChange = this.handleChange.bind(this);

    }


    componentDidMount() {
      loadWidths().then(columnDefinitions => {
        this.setState({
          columnDefinitions
        });
      });
    }
  
    onFilteringChange({ detail: { filteringText } }) {
      this.setState({
        filteringText
      });
    }
  
    onPaginationChange({ detail: { pageSize } }) {
      this.setState({
        pageSize
      });
    }
  
    onContentSelectionChange({ detail}) {
      const contentSelection = detail.contentSelection;
      const currentContentSelectorOptionGroup = this.state.contentSelectorOptions[0];
      this.setState({
        contentSelectorOptions: [{
          label: currentContentSelectorOptionGroup.label,
          options: currentContentSelectorOptionGroup.options.map(opt => ({
            id: opt.id,
            label: opt.label,
            editable: opt.editable,
            visible: contentSelection.indexOf(opt.id) !== -1
          }))
        }]
      });
    }
  
    onWrapLinesChange({ detail }) {
      this.setState({
        wrapLines: detail.value
      });
    }
  
    headerCounter(selectedDistributions, distributions) {
      return selectedDistributions.length
        ? `(${selectedDistributions.length}/${distributions.length})`
        : `(${distributions.length})`;
    }
  
    clearFilter() {
      this.setState({
        filteringText: ''
      });
    }
  
    deleteSelections(){
       // alert(this.state.selectedDistributions);
    }

     handleChange(e, target) {
      e.preventDefault();
      if (target==="selectedCategory"){
        this.setState({selectedCategory:  e.detail.selectedId});
        this.props.watcher.selectCategory(e.detail.selectedId);
      }

      if (target==="selectedType"){
        this.setState({selectedType:  e.detail.selectedId});
        this.props.watcher.selectType(e.detail.selectedId);

      }

      if (target === "selectedDate"){  
        this.setState({lastThirtyDaysSelected:  true});
        this.props.watcher.selectDate(true);
      }
    
      //console.log(e);
      if (e.detail.value!==undefined){
         
          if(target==="reject_reason")
          this.setState({reject_reason: e.detail.value})

      }
      
    }

    handleOk(e){
      e.preventDefault();
  
      this.setState({showRejectModal: false, cancelReject: false});
     // alert(this.state.reject_reason);
    }

    handleCancel(e){
      e.preventDefault();
      this.props.watcher.setFlashMessage(null);

      this.setState({cancelReject: true, reject_reason: '', showRejectModal: false});
    }

    render() {

      return (
        <div>
          {/*  // last 30 days button commented out for now. 
          &nbsp;  
          <Link to = '/myActivities' onClick = {(e) => {this.handleChange(e, "selectedDate")}}> <Button text = 'Last 30 days'/></Link>
           &nbsp;   &nbsp; 
          */}
          <Link to='/myActivities'><Button text='My Activities'/></Link>
          &nbsp; 
          <Link to='/allActivities'><Button text='All Activities'/></Link>
          &nbsp;
          <div style={{float: 'left', marginTop: '5px'}}>
            <b>Category: </b>&nbsp;&nbsp;
          </div>
          <div style={{width: '20%', float: 'left'}}>
           <Select name="category" options={categoryTypes} selectedId={this.state.selectedCategory}  onChange={(e) => {this.handleChange(e, "selectedCategory")}} ></Select>
           &nbsp;  &nbsp;
          </div>


          <div style={{float: 'left', marginTop: '5px', marginLeft: '8px'}}>
            <b> Type: </b>&nbsp;&nbsp;
          </div>
          <div style={{width: '20%', float: 'left'}}>
           <Select name="type" options={contributionTypes} selectedId={this.state.selectedType}  onChange={(e) => {this.handleChange(e, "selectedType")}} ></Select>
           &nbsp;  &nbsp;
          </div>

          <div style={{float: 'left', marginTop: '5px', marginLeft: '8px'}}>

          </div>

          &nbsp;
          { (this.props.isAdmin === true) ? 
           <span style={floatRightStyle}>
          <b>Administration:</b> &nbsp;
           <Link to='/profiles'><Button variant="primary" text='Profiles'/></Link>&nbsp;
         </span> : null 
          }
          <p/>

          <Modal
          visible={this.state.showRejectModal===true}
          header="Rejection Reason"
          footer={<span className="awsui-util-f-r">
          <Button variant="link" text="Cancel" onClick={(e) => {this.handleCancel(e)}}></Button>
          <Button variant="primary" text="Ok" onClick={(e) => {this.handleOk(e)}}></Button>
        </span>}
        >
          <span>
            <h5>
              Enter a reason for rejection here.
            </h5>
            <Textarea rows={3} name="reject_reason" value={this.state.reject_reason} onChange={(e) => {this.handleChange(e, "reject_reason")}}></Textarea>
        </span>
        </Modal>


        <Table
          columnDefinitions={this.state.columnDefinitions}
          items={this.props.distributions}
          wrapLines={true}
          stickyHeader={true}
          resizableColumns={true}
          onColumnWidthsChange={saveWidths}
          loading={this.props.loading}
          loadingText="Loading everyone's activity. This may take a moment..."
         
          header={(
            <TableHeader
              resultSize={this.props.distributions.length}
              selectedCategory={this.state.selectedCategory}
              selectedType={this.state.selectedType}
              selectedDistributions={this.state.selectedDistributions}
              counter={this.headerCounter(this.state.selectedDistributions, this.props.distributions)}
              watcher={this.props.watcher}
              table={this}
              disableAdd={this.props.disableAdd}
              reject_reason={this.state.reject_reason}
            />
          )}
          
          onWrapLinesChange={this.onWrapLinesChange.bind(this)}
        >

        <TableFiltering
          filteringLabel='Search Activities'
          filteringPlaceholder='Search Activities'
          filteringText={this.state.filteringText}
          onFilteringChange={this.onFilteringChange.bind(this)}
          filteringCountTextFunction={getFilterCounterText}
        />
          <TablePagination
            onPaginationChange={this.onPaginationChange}
            labels={paginationLabels}
            pageSize={this.state.pageSize}
          />
          <TableSorting sortableColumns={SORTABLE_COLUMNS}/>
        </Table>
        </div>
      )
    }
  }

  export default AllActivitiesTable;