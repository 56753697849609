/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createExplorerProfile = /* GraphQL */ `
  mutation CreateExplorerProfile(
    $input: CreateExplorerProfileInput!
    $condition: ModelExplorerProfileConditionInput
  ) {
    createExplorerProfile(input: $input, condition: $condition) {
      cognito_user
      email
      first_name
      last_name
      country
      region
      topicArea
      program_status
      legal_form
      link_to_nda
      is_disabled
      is_admin
      admin_category
      createdAt
      updatedAt
    }
  }
`;
export const updateExplorerProfile = /* GraphQL */ `
  mutation UpdateExplorerProfile(
    $input: UpdateExplorerProfileInput!
    $condition: ModelExplorerProfileConditionInput
  ) {
    updateExplorerProfile(input: $input, condition: $condition) {
      cognito_user
      email
      first_name
      last_name
      country
      region
      topicArea
      program_status
      legal_form
      link_to_nda
      is_disabled
      is_admin
      admin_category
      createdAt
      updatedAt
    }
  }
`;
export const deleteExplorerProfile = /* GraphQL */ `
  mutation DeleteExplorerProfile(
    $input: DeleteExplorerProfileInput!
    $condition: ModelExplorerProfileConditionInput
  ) {
    deleteExplorerProfile(input: $input, condition: $condition) {
      cognito_user
      email
      first_name
      last_name
      country
      region
      topicArea
      program_status
      legal_form
      link_to_nda
      is_disabled
      is_admin
      admin_category
      createdAt
      updatedAt
    }
  }
`;
export const createActivity = /* GraphQL */ `
  mutation CreateActivity(
    $input: CreateActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    createActivity(input: $input, condition: $condition) {
      id
      cognito_user
      region
      email
      output_type
      description
      link
      date_completed
      createdAt
      country
      reject_reason
      status
      category
      name
      impact
      language
      updatedAt
    }
  }
`;
export const updateActivity = /* GraphQL */ `
  mutation UpdateActivity(
    $input: UpdateActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    updateActivity(input: $input, condition: $condition) {
      id
      cognito_user
      region
      email
      output_type
      description
      link
      date_completed
      createdAt
      country
      reject_reason
      status
      category
      name
      impact
      language
      updatedAt
    }
  }
`;
export const deleteActivity = /* GraphQL */ `
  mutation DeleteActivity(
    $input: DeleteActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    deleteActivity(input: $input, condition: $condition) {
      id
      cognito_user
      region
      email
      output_type
      description
      link
      date_completed
      createdAt
      country
      reject_reason
      status
      category
      name
      impact
      language
      updatedAt
    }
  }
`;
