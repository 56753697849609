import React from 'react';

export function findContributionById(find){
    return contributionTypes.filter(x => x.id === find );
  }

export const contributionTypes =   [
    {
        label: "3rd Party Events",
        id: "3rd Party Events",
        score: 20,
        link: true,
        desc: <div>3rd Party Events</div>
    },
    {
        label: "Article",
        id: "Article",
        score: 20,
        link: true,
        desc: <div>Online technical publication on a social media platform like LinkedIn, publisher, magazine, trade journal, ...</div>
    },
    {
        label: "AWS Events",
        id: "AWS Events",
        score: 20,
        link: true,
        desc: <div>AWS Events</div>
    },
    {
        label: "Blog Post",
        id: "Blog Post",
        score: 20,
        link: true,
        desc: <div>Online technical publication on the AWS/APN blog, a company blog, a personal blog, a publishing platform like Medium, ...</div>
    },
    {
        label: "Case Study",
        id: "Case Study",
        score: 30,
        link: true,
        desc: <div>Online publication of reasearch at <a href="https://aws.amazon.com/solutions/case-studies/" target="_blank" rel="noopener noreferrer">https://aws.amazon.com/solutions/case-studies/</a>, ...</div>
    },
    {
        label: "Email/Newsletter",
        id: "Email/Newsletter",
        score: 40,
        link: true,
        desc: <div>Any sort of content delivered via email, including newsletters. Please report the email open numbers, not the number of emails sent</div>
    },
    {
        label: "News",
        id: "News",
        score: 40,
        link: true,
        desc: <div>Content created that discusses news about AWS</div>
    },
    {
        label: "Open Source Project",
        id: "Open Source Project",
        score: 40,
        link: true,
        desc: <div>Source code on open repositories like GitHub</div>
    },
    {
        label: "Podcast/Audio",
        id: "Podcast/Audio",
        score: 10,
        link: true,
        desc: <div>Any kind of audio-based content, from a podcast to Clubhouse, to songs about AWS.</div>
    },
    {
        label: "Presentation: In Person",
        id: "Presentation In Person",
        score: 20,
        link: true,
        desc: <div>An in-person presentation given at a live event such as a meetup, conference, etc.</div>
    },
    {
        label: "Presentation: Online",
        id: "Presentation Online",
        score: 20,
        link: true,
        desc: <div>Giving any type of presentation online to a user group, conference, live on Twitch or a YouTube event, etc.</div>
    },
    {
        label: "re:Invent",
        id: "reInvent",
        score: 20,
        link: true,
        desc: <div>re:Invent content that doesn’t fall into a service category</div>
    },
    {
        label: "Technical Presentation In Person",
        id: "Technical Presentation In Person",
        score: 40,
        link: true,
        desc: <div>Hosting an in-person User Group, community meeting, re:Invent re:Cap, etc.</div>
    },
    {
        label: "Technical Presentation Online",
        id: "Technical Presentation Online",
        score: 40,
        link: true,
        desc: <div>Hosting any kind of online event such as a User Group, tech day, etc.</div>
    },
    {
        label: "Video",
        id: "Video",
        score: 10,
        link: true,
        desc: <div>Any type of pre-recorded video content on any platform.</div>
    },
    {
        label: "Webinar",
        id: "Webinar",
        score: 10,
        link: true,
        desc: <div>Live, web-based video conference, digital/online conference, ...</div>
    },
    {
        label: "Whitepaper",
        id: "Whitepaper",
        score: 40,
        link: true,
        desc: <div>Authoritative report or guide on a subject at <a href="https://aws.amazon.com/whitepapers/"  target="_blank" rel="noopener noreferrer">https://aws.amazon.com/whitepapers/</a>, ..</div>
    },
    {
        label: "Other",
        id: "Other",
        score: 40,
        link: true,
        desc: <div>Anything that doesn’t fit into another category</div>
    }
];
