import React from "react";
import { useLocation } from "react-router-dom";

declare function cwr(operation: string, payload: any): void;

/*
 * Tracks changes in the location and sends it to RUM in order to have pages data matching the hash of the URL.
 */
const PageTracker = () => {

  let location = useLocation();
  React.useEffect(() => {
    if (typeof cwr !== 'undefined') {
       cwr("recordPageView", "#" + location.pathname);
    }
  }, [location]);

  return (
    <></>
  );
};

export default PageTracker;