import React from 'react';
import { API, graphqlOperation } from 'aws-amplify'

import { updateActivity } from "../graphql/mutations";
import { ColumnLayout, Form, FormSection, Textarea, DatePicker, FormField, Input, Select, Button } from '@amzn/awsui-components-react';

import { countryOptions, regionCountries } from '../dropdowns/CountriesReference'

import { contributionTypes, findContributionById } from './ActivitiesTypeReference'
import { categoryTypes } from './ActivitiesCategoryReference'
import {languageOptions} from "./LanguageReference";

class EditContribution extends React.Component {

    constructor(props) {
        super(props);

        // console.log("Editing activity: ", props);

        var selectedRegion = props.userProfile.region;
        var selectedCountryId = countryOptions.find(x => x.label === props.userProfile.country).id;

        this.state = {
            region: selectedRegion,
            country: selectedCountryId,
            type: props.selected.output_type,
            category: props.selected.category,
            date_completed: props.selected.date_completed,
            description: props.selected.description,
            link: props.selected.link,
            requiresLink: true,
            impact: props.selected.impact,
            language: props.selected.language,
            chars_left: 280,
            max_char: 280
        };

        this.handleChange = this.handleChange.bind(this);
    }    validURL(str) {
        var isValidUrl = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.·\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[·6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1·,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00·a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u·00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/i;
        return isValidUrl.test(str);
    }

    handleCancel(e) {
        e.preventDefault();
        this.props.watcher.cancelClicked();
    }

    handleChange(e, target) {
        e.preventDefault();
        let change = {}

        if (e.detail.selectedId !== undefined) {
            change[target] = e.detail.selectedId;
            this.setState(change);
            this.forceUpdate();
        }
        if (e.detail.value !== undefined) {
            change[target] = e.detail.value;
        }

        if ("region" === target) {
            this.setState({ country: regionCountries(e.detail.selectedId)[0].id });
        }

        if ("type" === target) {
            console.log(findContributionById(e.detail.selectedId)[0].link);

            this.setState({ requiresLink: findContributionById(e.detail.selectedId)[0].link });
        }

        this.setState(change);

        if (!this.validURL(this.state.link) && this.state.requiresLink === true) {
            this.setState({ linkValid: false });
        } else {
            this.setState({ linkValid: true });
        }

        // clear input errors
        if (this.state.date_completed !== '') {
            this.setState({ date_completedValid: true });
        }
        var date_completed = Date.parse(this.state.date_completed);

        var now = new Date();
        now.setDate(now.getDate() + 1);

        if (date_completed > now) {
            this.setState({ date_completedValid: false, date_completedErrorText: "Date cannot be in the future." });
        } else {
            this.setState({ date_completedErrorText: null });
        }

        if ("description" === target) {
            const charCount = e.detail.value.length;
            const maxChar = this.state.max_char;
            const charLength = maxChar - charCount;
            this.setState({ chars_left: charLength });

            if (charLength > 0 && charLength < 281) {
                this.setState({ descriptionValid: true });
            }
            else {
                this.setState({ descriptionValid: false });
            }
        }

        if("impact" === target) {
            this.setState({impactValid:true, impactErrorText: null});
            this.setState({value: this.state.impact})
            const re = /^[0-9\b]+$/;
            if (!(re.test(this.state.impact))) {  // if it contains a letter
                this.setState({impactValid:false, impactErrorText: "Impact can contain only numbers"});
            }
        }

        if ("language" === target) {
            this.setState({languageValid:true});
        }
    }

    handleSubmit(e) {
        
        if (this.state.category === undefined) {
            this.setState({ categoryValid: false });
            return;
        }        //  input errors
        if (this.state.date_completed === '') {
            this.setState({ date_completedValid: false });
            return;
        } else {
            var date_completed = Date.parse(this.state.date_completed);
            var now = new Date();
            if (date_completed > now) {
                this.setState({ date_completedValid: false, date_completedErrorText: "Date cannot be in the future." });
                return;
            }
        }        if (!this.validURL(this.state.link) && this.state.requiresLink === true) {
            return;
        }

        if (this.state.description === '') {
            this.setState({ descriptionValid: false });
            return;
        }

        if (this.state.description) {
            if (this.state.chars_left > 0) {
                this.setState({ descriptionValid: true });
            }
            else {
                this.setState({ descriptionValid: false });
                return;
            }
        }

        if (this.state.impact === '') {
            this.setState({impactValid:false});
            return;
        }
        else {
            const re = /^[0-9\b]+$/;
            if (!(re.test(this.state.impact))) {
                this.setState({impactValid: false, impactErrorText: "Impact can contain only numbers"});
                return;
            }
        }

        if (this.state.language === null) {
            this.setState({languageValid:false});
            return;
        }

        var country = countryOptions.find(x => x.id === this.state.country).label;

        const payload = {
            input: {
                id: this.props.selected.id,
                cognito_user: this.props.selected.cognito_user,
                region: this.state.region,
                email: this.props.selected.email,
                output_type: this.state.type,
                category: this.state.category,
                description: this.state.description,
                link: this.state.link,
                date_completed: this.state.date_completed,
                country: country,
                status: "APPROVED",
                impact: this.state.impact,
                language: this.state.language
            }
        }
        // console.log(payload);
        // this.createContribution(payload);
        this.updateApnContribution(payload);
    }

    // next, we create a function to interact with the API
    updateApnContribution = async (payload) => {

        await API.graphql(graphqlOperation(updateActivity, payload))
            .then(res => {
                console.log("ambassador output successfully updated: ", res)
                this.props.watcher.onContributionModified("Contribution updated successfully.");            })
            .catch(error => console.error("Something went wrong creating output: ", error));
    }
    render() {        var descriptionHTML = findContributionById(this.state.type)[0].desc;

        // console.log(this.state.type);
        //console.log(findContributionById(this.state.type)[0].desc);

        return (

            <Form
                header="Edit Activity"
                actions={<div>
                    <Button text="Cancel" variant="link" onClick={(e) => this.handleCancel(e)}></Button>
                    <Button text="Submit" variant="primary" onClick={(e) => this.handleSubmit(e)}></Button>
                </div>}            >
                <FormSection header="Enter details here">                    <ColumnLayout columns={2}>
                        <div data-awsui-column-layout-root="true">
                            <div>
                                <p />
                                <FormField label="Activity Type" description="What type of activity?" ariaRequired={true}>
                                    <Select name="type" options={contributionTypes} selectedId={this.state.type} onChange={(e) => { this.handleChange(e, "type") }} ></Select>
                                </FormField>
                                <p />
                                <FormField label="Activity Category" description="Which category?" ariaRequired={true}>
                                    <Select name="category" invalid={this.state.categoryValid === false} options={categoryTypes} selectedId={this.state.category} placeholder="Select Category" onChange={(e) => { this.handleChange(e, "category") }} ></Select>
                                </FormField>
                                <p />
                                <FormField label="Date Completed" description="When was the item created or published?" errorText={this.state.date_completedErrorText}>

                                    <DatePicker
                                        name="date_completed"
                                        placeholder="YYYY/MM/DD"
                                        todayLabel="Today"
                                        nextMonthLabel="Next month"
                                        previousMonthLabel="Previous month"
                                        ariaRequired={true}
                                        invalid={this.state.date_completedValid === false}
                                        value={this.state.date_completed}
                                        onChange={(e) => { this.handleChange(e, "date_completed") }}
                                    ></DatePicker>
                                </FormField>
                                <p />
                                <FormField label='Link' description="Provide a valid link to the contribution (include http://)" >
                                    <Input ariaRequired={true} name="link" value={this.state.link} invalid={this.state.linkValid === false} onChange={(e) => { this.handleChange(e, "link") }} />
                                </FormField>
                                <p />
                                <FormField label='Estimated Impact' description="Please give us any data on the impact of this contribution - analytics data on the number of views/engagements it had, how many people came to the event or viewed it online, etc." errorText= {this.state.impactErrorText}>
                                    <Input ariaRequired={true} name="impact" invalid={this.state.impactValid === false} value={this.state.impact} onChange={(e) => { this.handleChange(e, "impact") }} />
                                </FormField>                                <p />
                                <FormField label='Description' description="Describe the contribution in <280 characters" >
                                    <Textarea ariaRequired={true} name="description" invalid={this.state.descriptionValid === false} value={this.state.description} onChange={(e) => { this.handleChange(e, "description") }} />
                                    <p>{this.state.chars_left}</p>
                                </FormField>

                                <p />                            
                                </div>

                            <div>
                                <p />
                                <h5><b>Description</b></h5>
                                {descriptionHTML}
                            </div>

                            <FormField label='Language' description="Select the language in which the activity was delivered" >
                                <Select name="language"
                                        invalid={this.state.languageValid===false}
                                        options={languageOptions}
                                        selectedId={this.state.language}
                                        placeholder="Select language"
                                        onChange={(e) => {this.handleChange(e, "language")}} >
                                </Select>
                            </FormField>
                        </div>
                    </ColumnLayout>                
                    </FormSection>
            </Form>
        )
    }
}

export default EditContribution;
