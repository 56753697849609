const languageNames = [
    "Akan",
    "Amharic",
    "Arabic",
    "Assamese",
    "Awadhi",
    "Azerbaijani",
    "Balochi",
    "Belarusian",
    "Bengali",
    "Bhojpuri",
    "Burmese",
    "Catalan",
    "Cebuano (Visayan)",
    "Chewa",
    "Chhattisgarhi",
    "Chittagonian",
    "Czech",
    "Deccan",
    "Dhundhari",
    "Dutch",
    "Eastern Min",
    "English",
    "French",
    "Fula",
    "Gan Chinese",
    "German",
    "Greek",
    "Gujarati",
    "Haitian Creole",
    "Hakka",
    "Haryanvi",
    "Hausa",
    "Hebrew",
    "Hiligaynon",
    "Hindi",
    "Hmong",
    "Hungarian",
    "Igbo",
    "Ilocano",
    "Italian",
    "Japanese",
    "Javanese",
    "Jin",
    "Kannada",
    "Kazakh",
    "Khmer",
    "Kinyarwanda",
    "Kirundi",
    "Konkani",
    "Korean",
    "Kurdish",
    "Madurese",
    "Magahi",
    "Maithili",
    "Malagasy",
    "Malay/Indonesian",
    "Malayalam",
    "Mandarin",
    "Marathi",
    "Marwari",
    "Mossi",
    "Nepali",
    "Northern Min",
    "Norwegian",
    "Odia (Oriya)",
    "Oromo",
    "Pashto",
    "Persian",
    "Polish",
    "Portuguese",
    "Punjabi",
    "Quechua",
    "Romanian",
    "Russian",
    "Saraiki",
    "Serbo-Croatian",
    "Shona",
    "Sindhi",
    "Sinhalese",
    "Somali",
    "Southern Min",
    "Spanish",
    "Sundanese",
    "Swedish",
    "Sylheti",
    "Tagalog",
    "Tamil",
    "Telugu",
    "Te Reo Māori",
    "Thai",
    "Turkish",
    "Turkmen",
    "Ukrainian",
    "Urdu",
    "Uyghur",
    "Uzbek",
    "Vietnamese",
    "Wu (inc. Shanghainese)",
    "Xhosa",
    "Xiang (Hunnanese)",
    "Yoruba",
    "Yue (Cantonese)",
    "Zhuang",
    "Zulu"
];

export const languageOptions = languageNames.map(name => {
    return {
        "label": name,
        "id": name
    }
});