import React, { Component } from 'react';

import * as JWT from 'jwt-decode';
import { Auth } from 'aws-amplify'

import '@amzn/awsui-components-react/index.css';

import {
  AppLayout
} from '@amzn/awsui-components-react';
import { appLayoutLabels } from '../common/labels';
import '../styles/form.scss';

import ProfilesList from "./ProfilesList"


class Profiles extends Component {
   constructor(props){
     super(props);

     this.state = { 
       contentOrigins: [], toolsIndex: 0, toolsOpen: false, showCreateForm: false,
        currentUser: {"email": "nobody","administratior": false},
        contributions: [],
        flash_message: null,
        showProfiles: true,
        searchText: ''
      }
   }

  componentWillMount() {
    if(this.props.location.search!==undefined && this.props.location.search!==""){
      var searchText=this.props.location.search.substring(1,this.props.location.search.length);
      this.setState({searchText: searchText})
    }
    let parent = this;

    Auth.currentAuthenticatedUser()
    .then(user => {
      var token = JWT(user.signInUserSession.accessToken.jwtToken);
      //var isAdministrator = false;
      if (token['cognito:groups']!==undefined && token['cognito:groups'][0]==="ExplorersAdministrators") {
        user.attributes.administrator=true;
        parent.setState({ currentUser: user.attributes});
      }
      else {
        this.props.history.push({
          pathname: '/myActivities'
        })
      }
    }).catch(err => console.log(err)) // end authentication
  } // end will mount

  componentDidMount() {
  
  }

  render() {
    return (
      <div class='awsui'>
        <AppLayout
        contentType='table'
        notifications={this.state.flash_message}
        content={<ProfilesList filterText={this.state.searchText} watcher={this} isAdmin={this.state.currentUser.administrator} updateTools={() => this.setState({ toolsOpen: true })} />}
        navigationHide
        toolsHide
        labels={appLayoutLabels}
        />
      </div>
    );
  }
}

export default Profiles;