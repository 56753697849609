import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import { API, graphqlOperation } from 'aws-amplify'
import { Auth } from 'aws-amplify'


import {getExplorerProfile} from "../graphql/queries"
import {createExplorerProfile } from "../graphql/mutations"
import {updateExplorerProfile} from "../graphql/mutations"

import * as JWT from 'jwt-decode';
import '@amzn/awsui-components-react/index.css';
import '../styles/form.scss';

import {
  AppLayout,
  Button,
  ColumnLayout,
  Form,
  FormField,
  FormSection,
  Input,
  Select,
  Spinner
} from '@amzn/awsui-components-react';
import { appLayoutLabels } from '../common/labels';
import {regionOptions, countryOptions , regionCountries , adminRegionOptions} from '../dropdowns/CountriesReference'
import {topicOptions} from '../dropdowns/TopicsReference'

    export const 
    YesNo = [
        {
            "label": "YES",
            "id": "YES"
        },
        {
            "label": "NO",
            "id": "NO"
        }
    ];

    export const 
    TrueFalse = [
        {
            "label": "YES",
            "id": "true"
        },
        {
            "label": "NO",
            "id": "false"
        }
    ];

    export const 
    AmbassadorProgramStatusOptions = [
        {
            "label": "CANDIDATE",
            "id": "CANDIDATE"
        },
        {
            "label": "ACTIVE MEMBER",
            "id": "ACTIVE MEMBER"
        },
        {
            "label": "REJECTED",
            "id": "REJECTED"
        },
        {
            "label": "RETIRED",
            "id": "RETIRED"
        },
        {
            "label": "ADMINISTRATOR",
            "id": "ADMINISTRATOR"
        }
    ];


export const ProfileForm = ({ currentUser, watcher, newAmbassador , certifications, profile, ready , adminMenu , history}) => {
     if (ready===false) return null;
     let requiredFields = {'first_name': 'nameInvalid', 'region': 'regionInvalid', 'country': 'countryInvalid', 'topicArea': 'topicAreaInvalid'};

     function validationErrorsCheck() {
        let anyError = false;
        Object.keys(requiredFields).forEach(field => {
            let profileField = profile[field];
            if (!profileField || profileField === "") {
                let statusChange = {}
                statusChange[requiredFields[field]] = true;
                watcher.modifyState(statusChange);
                watcher.forceUpdate();
                anyError = true;
            }
        });
        return anyError;
     }
    
     function handleChange(e, target) {
        e.preventDefault();

        if (e.detail.value!==undefined){
            profile[target]=e.detail.value;
        }

        if (e.detail.selectedId!==undefined){
            profile[target]=e.detail.selectedId;
            if (target==="country"){
                profile[target]=countryOptions.find(x => x.id === e.detail.selectedId).label;
            }

            if (target==="region") {
                // rebuild the countries select
                // set country to the first item
                profile.region=e.detail.selectedId;
                // profile.country = regionCountries(e.detail.selectedId)[0].label;
                watcher.modifyState({profile: profile});
            }
        }

        if (requiredFields[target]) {
            let stateModification = {}
            stateModification[requiredFields[target]] = !profile[target] || profile[target] === "";
            watcher.modifyState(stateModification);
            watcher.forceUpdate();
        }
    }


    function handleSubmit(e){
        e.preventDefault();
        if (validationErrorsCheck()) {
            return;
        }
        Object.keys(profile).forEach((key) => (profile[key] === null || profile[key]==="" ) && delete profile[key]);
        if (newAmbassador===true) { 
            profile.program_status="ACTIVE MEMBER"; 

            // not authorized to update these fields 
            if(adminMenu!==true) { 
                delete profile['legal_form']; 
                delete profile['link_to_nda']; 
                delete profile['is_disabled']; 
                delete profile['is_admin']; 
                delete profile['admin_category']; 
                delete profile['public_sector']; 
            } 

            // create 
            const payload = {
                input: profile
            } 
            payload.input.cognito_user=currentUser.sub;
            createAPNAmbassadorProfile(payload); 
        } else { 
            // not authorized to update these fields 
            if(adminMenu!==true) { 
                delete profile['program_status']; 
                delete profile['legal_form']; 
                delete profile['link_to_nda']; 
                delete profile['is_disabled']; 
                delete profile['is_admin']; 
                delete profile['admin_category']; 
                delete profile['public_sector']; 
            }
            delete profile['createdAt']; 
            delete profile['updatedAt'];
            // update
            const payload = { 
                input: profile
            }
            updateAPNAmbassadorProfile(payload);
        } 
    } 


    const createAPNAmbassadorProfile = async (payload) => {
        await API.graphql(graphqlOperation(createExplorerProfile, payload))
        .then(res =>  {
            if (res.data.createExplorerProfile!=null){
                console.log("ambassador profile successfully created: ", res )
                history.push({
                 pathname: '/viewProfile/' + profile.cognito_user,
                 state: { flash_message: "Profile successfully updated." }
               })
            }else{
               // alert('something went wrong.');
            }
        })
        .catch(error => console.error("Something went wrong creating profile: ", error));
      }

    const updateAPNAmbassadorProfile = async (payload) => {
        await API.graphql(graphqlOperation(updateExplorerProfile, payload))
        .then(res =>  {

            if (res.data.updateExplorerProfile!=null){
                console.log("ambassador profile successfully update: ", res )
                history.push({
                 pathname: '/viewProfile/' + profile.cognito_user,
                 state: { flash_message: "Profile successfully updated." }
               })
               // Since the profile has been updated, the state of the header changes (name, surname, completion status,...) 
               // Ideally there would be a way to update this status without refreshing the page, but at this point this is just easier. 
               window.location.reload(false); 
            } else{
                //alert('something went wrong.');
            }
          
        })
        .catch(error => console.error("Something went wrong updating profile: ", error));
      }


    return (
        <div>

{(ready===true)? 
    <Form
    header="Profile Details"
    actions={<></>}
    >

<ColumnLayout columns={2}>
  <div data-awsui-column-layout-root="true">

    <div>
  
    <FormSection header="Enter Profile Details">
         <FormField label="First Name" ariaRequired={true}>
            <Input name="first_name" value={profile.first_name} invalid={watcher.state.nameInvalid} onChange={(e) => {handleChange(e, "first_name")}} ></Input>
        </FormField>
        <p />
        <FormField label="Last Name" ariaRequired={true}>
            <Input name="last_name"  value={profile.last_name} onChange={(e) => {handleChange(e, "last_name")}} ></Input>
        </FormField>
       
        <p />


        <FormField label="Region" ariaRequired={true}>
            <Select name="region" options={regionOptions} selectedId={profile.region} 
                    invalid={watcher.state.regionInvalid}
                    onChange={(e) => {handleChange(e, "region")}}>
            </Select>
        </FormField> 
        <p />

        
            <FormField label="Country" ariaRequired={true}>
            <Select name="country" options={regionCountries(profile.region)} 
                    invalid={watcher.state.countryInvalid}
                    selectedId={(regionCountries(profile.region).find(x => x.label === profile.country) || {}).id} onChange={(e) => {handleChange(e, "country")}} ></Select>
            </FormField>
    
        <p />
        
        <FormField label="Your topic area" ariaRequired={true}>
            <Select name="topicArea" options={topicOptions} invalid={watcher.state.topicAreaInvalid} selectedId={profile.topicArea} onChange={(e) => {handleChange(e, "topicArea")}}>
            </Select>
        </FormField> 
        <p />
 
        </FormSection>

        <div>
        <Link to={"/viewProfile/" + profile.cognito_user}><Button text='Cancel'/></Link>&nbsp;

        <Button text="Save" variant="primary" onClick={(e) => handleSubmit(e)}></Button>
         </div>
    </div>
    <div>
    {(adminMenu!==true)?
        null
            :
             <>
            <FormSection header="Administration Details">
            <p />
            <FormField label="Email" ariaRequired={true} description="** Must match the cognito login email.">
                <Input name="email"   value={profile.email} onChange={(e) => {handleChange(e, "email")}} ></Input>
            </FormField>
            <p/>
            <FormField label="Legal Form" description="AWS Internal: Is legal satisfied?" ariaRequired={true}>
                <Select name="legal_form" options={YesNo} selectedId={!profile.legal_form ? "" :profile.legal_form.toUpperCase()} onChange={(e) => {handleChange(e, "legal_form")}}></Select>
            </FormField>
            <p />
            <FormField label="Link to NDA" description="AWS Internal: Link to NDA - Salesforce document." ariaRequired={true}>
                <Input name="link_to_nda" value={profile.link_to_nda} onChange={(e) => {handleChange(e, "link_to_nda")}} ></Input>
            </FormField>
            <p />
            <FormField label="Public Sector" ariaRequired={true} description="Is this a public sector profile?">
            <Select name="public_sector" options={YesNo} selectedId={!profile.public_sector ? "" :profile.public_sector.toUpperCase()} onChange={(e) => {handleChange(e, "public_sector")}}></Select>
            </FormField>
            <p />
            <FormField label="Program Status" description="What is the role of this user?" ariaRequired={true}>
            <Select name="program_status" options={AmbassadorProgramStatusOptions} selectedId={profile.program_status} onChange={(e) => {handleChange(e, "program_status")}} ></Select>
            </FormField>
            <p />
        </FormSection>
 

        <FormSection header="Application Administration">
                    
        <FormField label="Application Administrator" description="AWS Internal: Is admin of this application?" ariaRequired={true}>
            <Select name="is_admin" options={TrueFalse} selectedId={!profile.is_admin ? "false" :profile.is_admin.toString()} onChange={(e) => {handleChange(e, "is_admin")}}></Select>
        </FormField>
        <p />
        <FormField label="Administration Region" ariaRequired={true}>
      
        <Select name="admin_category" options={adminRegionOptions} selectedId={!profile.admin_category ? profile.region : profile.admin_category} onChange={(e) => {handleChange(e, "admin_category")}}>
        </Select>

        </FormField>
        <p />
        <FormField label="Is disabled" description="Is this account disabled ? User will not be able to login." ariaRequired={true}>
            <Select name="is_admin" options={TrueFalse} selectedId={!profile.is_disabled ? "false" :profile.is_disabled.toString()} onChange={(e) => {handleChange(e, "is_disabled")}}></Select>
        </FormField>
        <p />
        
        </FormSection>
        </>
    }
 
    </div>
   
  </div>
</ColumnLayout>
</Form>
: 
<Spinner></Spinner>
}
      </div>
    );
  };

class EditProfile extends Component {
   constructor(props){
     super(props);

     this.state = { 
        profile: {"cognito_user" : this.props.match.params.id , first_name: "" , last_name: "" , partner_name:"" , current_usage_code: "" , current_usage_code_expiry: "", previous_usage_code:"",previous_usage_code_expiry:"",segment:"",psa: "" , link_to_nda: "" , legal_form: "", is_admin: false , admin_category: "NA" },
        certifications: [],
        flash_message: null,
        ready: true,
        isAdministrator: false,
        newAmbassador: false,
        nameInvalid: false,
        topicAreaInvalid: false,
        regionInvalid: false,
        countryInvalid: false
      }
   }

    componentDidMount() {
        let parent = this;
      
        Auth.currentAuthenticatedUser()
        .then(user => {
          var token = JWT(user.signInUserSession.accessToken.jwtToken);
          var isAdministrator = false;
          if (token['cognito:groups']!==undefined && token['cognito:groups'][0]==="ExplorersAdministrators")
          {isAdministrator=true;}
          user.attributes.administrator=isAdministrator;
          var profile = this.state.profile;
          profile.email=user.attributes.email;
          parent.setState({ currentUser: user.attributes, profile: profile, isAdministrator: isAdministrator});
          this.getProfileForUser(user.username);
        }).catch(err => console.log(err)) // end authenticationn
    } // end will mount

 getProfileForUser(id){
    // fetch contributions for this user
    API.graphql(graphqlOperation(getExplorerProfile,{"cognito_user":id}))
    .then(res => { 
      var theseCertifications = [];

      if (res.data.getExplorerProfile!==null){
         // parse certifications
        if (
            res.data.getExplorerProfile!==null && 
            res.data.getExplorerProfile.certifications!==undefined && 
            res.data.getExplorerProfile.certifications!=="" && 
            res.data.getExplorerProfile.certifications!==null){
            theseCertifications = JSON.parse(atob(res.data.getExplorerProfile.certifications));
        }

        this.setState({ profile: res.data.getExplorerProfile , ready: true , certifications: theseCertifications})   
      }else{
        this.setState({ newAmbassador: true, ready: true , certifications: theseCertifications})
      }
    

    }).catch(error => {
      console.log("Couldn't establish ambassador ", error);
      this.setState({ error: "Unable to load profile." , ready: true , newAmbassador: true})
    });

  }

  modifyState(state){
      this.setState(state);
      this.forceUpdate();
  }

  render(){

   // console.log(this.props);

    return (
      <div className='awsui'>

        <AppLayout
        contentType='form'
        content={<ProfileForm 
            currentUser={this.state.currentUser}
            watcher={this}
            newAmbassador={this.state.newAmbassador}
            certifications={this.state.certifications}
            profile={this.state.profile}
            ready={this.state.ready}
            adminMenu={this.state.isAdministrator}
            history={this.props.history}/>
        }
        navigationHide
        toolsHide
        labels={appLayoutLabels}
        />
      </div>
    );
  }
}

export default EditProfile;
