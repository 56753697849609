import React from 'react';
import { API, graphqlOperation } from 'aws-amplify'

import { createActivity } from "../graphql/mutations";
import { ColumnLayout, Form, FormSection, Textarea, DatePicker, FormField, Input, Select, Button } from '@amzn/awsui-components-react';

import {countryOptions , regionCountries} from '../dropdowns/CountriesReference'

import  {contributionTypes, findContributionById} from './ActivitiesTypeReference'
import  {categoryTypes} from './ActivitiesCategoryReference'
import  {languageOptions} from './LanguageReference'

class AddContribution extends React.Component {

    constructor(props) {
        super(props);
        var selectedRegion = props.userProfile.region;
        var selectedCountryId =  countryOptions.find(x => x.label === props.userProfile.country).id;
        this.state = {
            region: selectedRegion, country: selectedCountryId, date_completed: '', description: '', link: '', requiresLink: true, impact: '', chars_left: 280, max_char:280
        };
        this.handleChange = this.handleChange.bind(this);
    }

     validURL(str) {
        var isValidUrl = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.​\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[​6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1​,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00​a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u​00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/i;
        return isValidUrl.test(str);
     }

    handleCancel(e) {
        e.preventDefault();
        this.props.watcher.cancelClicked();
    }

    handleChange(e, target) {
        //console.log("user profile: " + this.props.userProfile.first_name + this.props.userProfile.last_name);
        e.preventDefault();
        let change = {}
       
        if(e.detail.selectedId!==undefined){
            change[target]=e.detail.selectedId;
            this.setState(change);
            this.forceUpdate();
        }
        if(e.detail.value!==undefined){
            change[target]=e.detail.value;
        }

        if ("region"===target)
        {
            this.setState({country: regionCountries(e.detail.selectedId)[0].id});
        }

        if ("type"===target)
        {
           // console.log(findContributionById(e.detail.selectedId)[0].link);
            this.setState({typeValid:true});
            this.setState({requiresLink: findContributionById(e.detail.selectedId)[0].link});
        }

        this.setState(change);

        if (!this.validURL(this.state.link) && this.state.requiresLink===true){
            this.setState({linkValid:false});
        }else{
            this.setState({linkValid:true});
        }

        // clear input errors
        if (this.state.date_completed!==''){
            this.setState({date_completedValid:true});
        }
        var date_completed = Date.parse(this.state.date_completed); 

        var now = new Date();
        now.setDate(now.getDate() + 1);

        if (date_completed > now) {
            this.setState({date_completedValid:false , date_completedErrorText: "Date cannot be in the future."});
        }else{
            this.setState({date_completedErrorText: null});
        }

        if ("description"===target) {
            const charCount = e.detail.value.length;
            const maxChar = this.state.max_char;
            const charLength = maxChar - charCount;
            this.setState({ chars_left: charLength });

            if (charLength > 0 && charLength <281) {
                this.setState({descriptionValid:true});
                }
            else {
                this.setState({descriptionValid:false});
            }
        }

        if("impact" === target) {
            this.setState({impactValid:true, impactErrorText: null});
            this.setState({value: this.state.impact})
            const re = /^[0-9\b]+$/;
            if (!(re.test(this.state.impact))) {  // if it contains a letter
                this.setState({impactValid:false, impactErrorText: "Impact can contain only numbers"});
            }
        }

        if ("language" === target) {
            this.setState({languageValid:true});
        }
    }

    handleSubmit() {
        if (this.state.type===undefined){
            this.setState({typeValid:false});
            return;
        }

        if (this.state.category===undefined){
            this.setState({categoryValid:false});
            return;
        }

        //  input errors
        if (this.state.date_completed===''){
            this.setState({date_completedValid:false});
            return;
        } else {
            var date_completed = Date.parse(this.state.date_completed);  
            var now = new Date();
            if (date_completed > now) {
                this.setState({date_completedValid:false , date_completedErrorText: "Date cannot be in the future."});
                return;
            }
        }

        if (!this.validURL(this.state.link) && this.state.requiresLink===true){
            return;
        }

        // to make sure the description is less than 280 chars

        if (this.state.description===''){
            this.setState({descriptionValid:false});
            return;
        }

        if (this.state.description) {
            if(this.state.chars_left > 0){
                this.setState({descriptionValid:true});
            }
            else {
                this.setState({descriptionValid:false});
                return; 
            }
        }

        if (this.state.impact === '') {
            this.setState({impactValid:false});
            return;
        }
        else {
            const re = /^[0-9\b]+$/;
            if (!(re.test(this.state.impact))) {
                this.setState({impactValid: false, impactErrorText: "Impact can contain only numbers"});
                return;
            }
        }

        var country = countryOptions.find(x => x.id === this.state.country).label;
        
        var explorerName = this.props.userProfile.first_name + " " +this.props.userProfile.last_name;
        const payload = {
            input: {
              cognito_user: this.props.watcher.state.currentUser.sub,
              region:this.state.region,
              email: this.props.watcher.state.currentUser.email,
              output_type: this.state.type,
              category: this.state.category,
              description: this.state.description,
              link: this.state.link,
              date_completed: this.state.date_completed,
              country: country,
              status: "APPROVED",
              name: explorerName,
              language: this.state.language,
              impact: this.state.impact
            } 
          }
          this.createAPNContribution(payload);
    }
    
      // next, we create a function to interact with the API
  createAPNContribution = async (payload) => {
    await API.graphql(graphqlOperation(createActivity, payload))
    .then(() =>  {
      this.props.watcher.onContributionModified("Contribution created successfully.");
    })
    .catch(error => console.error("Something went wrong creating output: ", error));
  }

    render() {
        let descriptionHTML =<div>Choose from the available contribution types.</div>
        if (this.state.type!==undefined){
            descriptionHTML  = findContributionById(this.state.type)[0].desc;
        }
        return (
            <Form
                header="Add Activity"
                actions={<div>
                    <Button text="Cancel" variant="link" onClick={(e) => this.handleCancel(e)}/>
                    <Button text="Submit" variant="primary" onClick={(e) => this.handleSubmit(e)}/>
                </div>}
                    >
                    <FormSection header="Enter details here">
                    <ColumnLayout columns={2}>
                    <div data-awsui-column-layout-root="true">
                    <div>
                  <p />
                  <FormField label="Activity Type" description="What type of activity? Do not submit Tweets." ariaRequired={true}>
                      <Select name="type" invalid={this.state.typeValid===false} options={contributionTypes} selectedId={this.state.type} placeholder="Select Type" onChange={(e) => {this.handleChange(e, "type")}} />
                  </FormField>
                  <p />
                  <FormField label="Activity Category" description="Which category?" ariaRequired={true}>
                      <Select name="category" invalid={this.state.categoryValid===false} options={categoryTypes} selectedId={this.state.category} placeholder="Select Category" onChange={(e) => {this.handleChange(e, "category")}} />
                  </FormField>
                  <p />
                  <FormField label="Date Completed" description="When was the item created or published?"  errorText={this.state.date_completedErrorText}>

                      <DatePicker
                          name="date_completed"
                          placeholder="YYYY/MM/DD"
                          todayLabel="Today"
                          nextMonthLabel="Next month"
                          previousMonthLabel="Previous month"
                          ariaRequired={true}
                          invalid={this.state.date_completedValid===false}
                         
                          onChange={(e) => {this.handleChange(e, "date_completed")}}
                      />
                  </FormField>
                  <p />
                  <FormField label='Link' description="Provide a valid link to the contribution (include http://)" >
                      <Input ariaRequired={true} name="link"  invalid={this.state.linkValid===false} onChange={(e) => {this.handleChange(e, "link")}} />
                  </FormField>

                  <p />

                  <FormField label='Impact after 30 days' description="How many people did you reach? Please provide data on the number of views/listens/pageviews/downloads/etc. your content had, or how many people came to the event, viewed it online, etc. This number is only viewable to the AWS team." errorText= {this.state.impactErrorText}>
                      <Input ariaRequired={true} name="impact"  invalid={this.state.impactValid===false} input value={this.state.value} onChange={(e) => {this.handleChange(e, "impact")}} />
                  </FormField>
                  <p />

                  <FormField label='Description' description="Describe the contribution in <280 characters" >
                    <Textarea ariaRequired={true} name="description" invalid={this.state.descriptionValid===false}  onChange={(e) => {this.handleChange(e, "description")}} />
                    <p>{this.state.chars_left}</p>
                  </FormField>
                  <p />
                  </div>
                    <div>
                    <p/>
                    <h5><b>Description</b></h5>
                    {descriptionHTML}
                    </div>

                    <FormField label='Language' description="Select the language in which the activity was delivered" >
                        <Select name="language"
                                invalid={this.state.languageValid===false}
                                options={languageOptions}
                                selectedId={this.state.language || "English"}
                                placeholder="Select language"
                                onChange={(e) => {this.handleChange(e, "language")}} >
                        </Select>
                    </FormField>
               </div>
                </ColumnLayout>
              </FormSection>
          </Form>
        )
    }

}

export default AddContribution;
