/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getExplorerProfile = /* GraphQL */ `
  query GetExplorerProfile($cognito_user: String!) {
    getExplorerProfile(cognito_user: $cognito_user) {
      cognito_user
      email
      first_name
      last_name
      country
      region
      topicArea
      program_status
      legal_form
      link_to_nda
      is_disabled
      is_admin
      admin_category
      createdAt
      updatedAt
    }
  }
`;
export const listExplorerProfiles = /* GraphQL */ `
  query ListExplorerProfiles(
    $cognito_user: String
    $filter: ModelExplorerProfileFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listExplorerProfiles(
      cognito_user: $cognito_user
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        cognito_user
        email
        first_name
        last_name
        country
        region
        topicArea
        program_status
        legal_form
        link_to_nda
        is_disabled
        is_admin
        admin_category
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getActivity = /* GraphQL */ `
  query GetActivity($id: ID!) {
    getActivity(id: $id) {
      id
      cognito_user
      region
      email
      output_type
      description
      link
      date_completed
      createdAt
      country
      reject_reason
      status
      category
      name
      impact
      language
      updatedAt
    }
  }
`;
export const listActivitys = /* GraphQL */ `
  query ListActivitys(
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivitys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognito_user
        region
        email
        output_type
        description
        link
        date_completed
        createdAt
        country
        reject_reason
        status
        category
        name
        impact
        language
        updatedAt
      }
      nextToken
    }
  }
`;
export const activitiesByStatusAndDate = /* GraphQL */ `
  query ActivitiesByStatusAndDate(
    $status: String
    $date_completed: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ActivitiesByStatusAndDate(
      status: $status
      date_completed: $date_completed
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognito_user
        region
        email
        output_type
        description
        link
        date_completed
        createdAt
        country
        reject_reason
        status
        category
        name
        impact
        language
        updatedAt
      }
      nextToken
    }
  }
`;
export const activitiesByCategory = /* GraphQL */ `
  query ActivitiesByCategory(
    $category: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ActivitiesByCategory(
      category: $category
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognito_user
        region
        email
        output_type
        description
        link
        date_completed
        createdAt
        country
        reject_reason
        status
        category
        name
        impact
        language
        updatedAt
      }
      nextToken
    }
  }
`;
export const activitiesByCategoryAndDate = /* GraphQL */ `
  query ActivitiesByCategoryAndDate(
    $category: String
    $date_completed: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ActivitiesByCategoryAndDate(
      category: $category
      date_completed: $date_completed
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognito_user
        region
        email
        output_type
        description
        link
        date_completed
        createdAt
        country
        reject_reason
        status
        category
        name
        impact
        language
        updatedAt
      }
      nextToken
    }
  }
`;
export const activitiesByTypeAndDate = /* GraphQL */ `
  query ActivitiesByTypeAndDate(
    $output_type: String
    $date_completed: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ActivitiesByTypeAndDate(
      output_type: $output_type
      date_completed: $date_completed
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognito_user
        region
        email
        output_type
        description
        link
        date_completed
        createdAt
        country
        reject_reason
        status
        category
        name
        impact
        language
        updatedAt
      }
      nextToken
    }
  }
`;
