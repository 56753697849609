import React from 'react';
import { Link } from 'react-router-dom'
import { API, graphqlOperation } from 'aws-amplify'

import { deleteActivity } from "../graphql/mutations";
import { getFilterCounterText } from '../common/tableCounterStrings';


import {
    COLUMN_DEFINITIONS,
    CONTENT_SELECTOR_OPTIONS,
    SORTABLE_COLUMNS,
  } from './table-config-myactivities';
  import {
    distributionSelectionLabels
  } from '../common/labels';
  import {
    Button,
    Table,
    TableSelection,
    TableFiltering,

    TableSorting,
    Flash
  } from '@amzn/awsui-components-react';
  import '../styles/table.scss';
  import { createPropertyStorage } from '../common/createPropertyStorage';
  import * as localStorage from '../common/localStorage';
  import { mapWithColumnDefinitionIds, addToColumnDefinitions } from '../common/columnDefinitionsHelper';

  var floatRightStyle = {
    float: 'right'
  };

  const widthsStorage = createPropertyStorage('React-Table-Widths', localStorage);
  const saveWidths = e => widthsStorage.save(mapWithColumnDefinitionIds(COLUMN_DEFINITIONS, 'width', e.detail.widths));
  const loadWidths = () =>
    widthsStorage.load().then(columns => addToColumnDefinitions(COLUMN_DEFINITIONS, 'width', columns));
  
    export const TableHeader = ({ selectedDistributions, counter, updateTools , watcher , table, disableAdd}) => {
        const isOnlyOneSelected = selectedDistributions.length === 1;
      
        function handleClick(e) {
            e.preventDefault();
            watcher.createClicked();
          }

          function handleEdit(e) {
            e.preventDefault();
            watcher.editClicked(selectedDistributions);
          }

          function handleDelete(e) {
            e.preventDefault();
           
            var payload={
              input: {
                "id": selectedDistributions[0].id
              }
            }

            console.log(JSON.stringify(payload));
            delContribution(payload);
           
          }


          const delContribution = async (payload) => {
            await API.graphql(graphqlOperation(deleteActivity, payload))
            .then(res =>  {
    
                console.log(JSON.stringify(res));

                if (res.data.deleteActivity!=null){
                    console.log("contribution successfully deleted: ", res );
                   

                    var newFlash = <Flash dismissible={true} type="success" visible={true} content={"Successfully deleted item with id:" + res.data.deleteActivity.id}/>;
                    watcher.setFlashMessage(newFlash);
                    watcher.recursiveAPNContributionsForUser();


                }else{

                    watcher.setFlashMessage(<Flash dismissible={true} type="error"  visible={true} content={"Something went wrong. Try again later."} />);
                }
              
            })
            .catch(error => console.error("Something went wrong updating contribution: ", error));
          }

        return (
          <div className='awsui-util-action-stripe'>
            <div className='awsui-util-action-stripe-title'>
              <h1 className='awsui-util-font-size-3 awsui-util-d-ib'>
                <strong>My Activities </strong>
              </h1>
            </div>
            <div className='awsui-util-action-stripe-group'>
              <Button text='Delete' disabled={!isOnlyOneSelected } onClick={handleDelete}/>

              <Button text='Edit' disabled={!isOnlyOneSelected } onClick={handleEdit}/>
            
              <Button variant='primary' disabled={disableAdd}
              text='Add Activity' onClick={handleClick}/>
            </div>

          </div>
        );
      };
  
class ContributionsList extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        columnDefinitions: COLUMN_DEFINITIONS,
        selectedDistributions: [],
        filteringText: '',
        pageSize: 1000,
        contentSelectorOptions: CONTENT_SELECTOR_OPTIONS,
        wrapLines: false,
        isAdmin: false
      };
      this.onPaginationChange = this.onPaginationChange.bind(this);
    }


    componentDidMount() {

      //console.log(this.props.currentUser);
      loadWidths().then(columnDefinitions => {
        this.setState({
          columnDefinitions
        });
      });
    }
  
    onFilteringChange({ detail: { filteringText } }) {
      this.setState({
        filteringText
      });
    }
  
    onPaginationChange({ detail: { pageSize } }) {
      this.setState({
        pageSize
      });
    }
  
    onContentSelectionChange({ detail}) {
      const contentSelection = detail.contentSelection;
      const currentContentSelectorOptionGroup = this.state.contentSelectorOptions[0];
      this.setState({
        contentSelectorOptions: [{
          label: currentContentSelectorOptionGroup.label,
          options: currentContentSelectorOptionGroup.options.map(opt => ({
            id: opt.id,
            label: opt.label,
            editable: opt.editable,
            visible: contentSelection.indexOf(opt.id) !== -1
          }))
        }]
      });
    }
  
    onWrapLinesChange({ detail }) {
      this.setState({
        wrapLines: detail.value
      });
    }
  
    headerCounter(selectedDistributions, distributions) {
      return selectedDistributions.length
        ? `(${selectedDistributions.length}/${distributions.length})`
        : `(${distributions.length})`;
    }
  
    clearFilter() {
      this.setState({
        filteringText: ''
      });
    }

    render() {



      return (
        <div>
         <span>
          { (this.props.isAdmin === true) ? 
           <span style={floatRightStyle}>
          <b>Administration:</b> &nbsp;
           <Link to='/profiles'><Button variant="primary" text='Profiles'/></Link>&nbsp;
         </span> : null 
          }
          <Link to='/myActivities'><Button text='My Activities'/></Link>
          &nbsp; 
          <Link to='/allActivities'><Button text='All Activities'/></Link>
          <p/>
          </span>

        <Table
          columnDefinitions={this.state.columnDefinitions}
          items={this.props.distributions}
          wrapLines={true}
          stickyHeader={true}
          resizableColumns={true}
          onColumnWidthsChange={saveWidths}
          loading={this.props.loading}
          loadingText="Loading your activities. This may take a moment..."
          header={(
            <TableHeader
              selectedDistributions={this.state.selectedDistributions}
              counter={this.headerCounter(this.state.selectedDistributions, this.props.distributions)}
              watcher={this.props.watcher}
              table={this}
              disableAdd={this.props.disableAdd}
            />
          )}
          
          onWrapLinesChange={this.onWrapLinesChange.bind(this)}
        >

        <TableFiltering
          filteringLabel='Search Contributions'
          filteringPlaceholder='Search Contributions'
          filteringText={this.state.filteringText}
          onFilteringChange={this.onFilteringChange.bind(this)}
          filteringCountTextFunction={getFilterCounterText}
        />


          <TableSorting sortableColumns={SORTABLE_COLUMNS} sortingColumn={"date"} sortingDescending={true}/>

          {/* Pagination removed on 24-02-2022
          <TablePagination
            onPaginationChange={this.onPaginationChange}
            labels={paginationLabels}
            pageSize={this.state.pageSize}
          />
          */}

          <TableSelection
            selectionType={"single"}
            selectedItems={this.state.selectedDistributions}
            labels={distributionSelectionLabels}
            onSelectionChange={evt => this.setState({selectedDistributions: evt.detail.selectedItems})}
          />
    
        
        </Table>
        </div>
      )
    }
  }

  export default ContributionsList;