export function findCategoryById(find){
    return categoryTypes.filter(x => x.id === find );
  }

export const categoryTypes =   [
    {
        label: "Cloud Operations",
        id: "Cloud Operations",
    },
    {
        label: "Community/Career/Other",
        id: "Community/Career/Other",
    },
    {
        label: "Containers",
        id: "Containers",
    },
    {
        label: "Data (Databases + Analytics + BI)",
        id: "Data (Databases + Analytics + BI)",
    },
    {
        label: "Dev Tools",
        id: "Dev Tools",
    },
    {
        label: "Front End Web & Mobile",
        id: "Mobile",
    },
    /*{
        label: "Game Tech",
        id: "Game Tech",
    },*/
    {
        label: "Generative AI",
        id: "Generative AI",
    },
    /*{
        label: "Graviton/ARM Development",
        id: "Graviton/ARM Development",
    },*/
    {
        label: "Machine Learning/GenAI",
        id: "Machine Learning/GenAI",
    },
    {
        label: "Multiple AWS Services",
        id: "Multiple AWS Services",
    },
    {
        label: "Networking & Content Delivery",
        id: "Networking & Content Delivery",
    },
    {
        label: "Security",
        id: "Security",
    },
    {
        label: "Serverless",
        id: "Serverless",
    },
    /*{
        label: "Storage",
        id: "Storage",
    },*/
    {
        label: "Other AWS Services",
        id: "Other AWS Services",
    }
];

