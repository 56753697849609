
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { I18n } from 'aws-amplify';

import Amplify from 'aws-amplify'

import config from './aws-exports'
Amplify.configure(config)

__webpack_nonce__ = 'c29tZSBjb29sIHN0cmluZyB3aWxsIHBvcCB1cCAxMjM='; // eslint-disable-line no-undef


const authScreenLabels = {
  en: {
      'User does not exist': 'Invalid username or password',
      'User already exists': 'Account details are invalid',
  }
};

I18n.setLanguage('en');
I18n.putVocabularies(authScreenLabels);


ReactDOM.render(
    <div className="awsui">
      <App/>
    </div>,
    document.getElementById('root')
  );