import React from 'react';
import { Link } from 'react-router-dom'
import { Auth } from 'aws-amplify'

import {
    COLUMN_DEFINITIONS,
    CONTENT_SELECTOR_OPTIONS,
    SORTABLE_COLUMNS,
  } from './profiles-table-config';
  import {
    paginationLabels,
    distributionSelectionLabels
  } from '../common/labels';
  import {
    Button,
    Table,
    TableFiltering,
    TablePagination,
    TableSelection,
    TableSorting,
  } from '@amzn/awsui-components-react';
  import { getFilterCounterText } from '../common/tableCounterStrings';
  import '../styles/table.scss';
  import { createPropertyStorage } from '../common/createPropertyStorage';
  import * as localStorage from '../common/localStorage';
  import { mapWithColumnDefinitionIds, addToColumnDefinitions } from '../common/columnDefinitionsHelper';
  import { API, graphqlOperation } from 'aws-amplify'

  import { listExplorerProfiles  , getExplorerProfile} from "../graphql/queries";


  var floatRightStyle = {
    float: 'right'
  };
  const widthsStorage = createPropertyStorage('React-Table-Widths', localStorage);
  const saveWidths = e => widthsStorage.save(mapWithColumnDefinitionIds(COLUMN_DEFINITIONS, 'width', e.detail.widths));
  const loadWidths = () =>
    widthsStorage.load().then(columns => addToColumnDefinitions(COLUMN_DEFINITIONS, 'width', columns));
  
    export const TableHeader = ({ selectedDistributions, counter, updateTools , watcher , table , region }) => {
        const isOnlyOneSelected = selectedDistributions.length === 1;
  

        return (
          <div className='awsui-util-action-stripe'>
            <div className='awsui-util-action-stripe-title'>
              <h1 className='awsui-util-font-size-3 awsui-util-d-ib'>
                <strong>Ambassador Profiles (Region: {region})</strong>
              </h1>
            </div>
            <div className='awsui-util-action-stripe-group'>
              {
                (isOnlyOneSelected) ? 
                <div><Link to={"/viewProfile/" + selectedDistributions[0].cognito_user}>&nbsp;<Button disabled={!isOnlyOneSelected} text='View Details'/></Link>&nbsp;</div>
                : 
                <div><a href="#vd"><Button disabled={!isOnlyOneSelected} text='View Details'/></a>&nbsp;</div>
              }
              {
                /*
              <a><Button text='Delete' disabled={selectedDistributions.length === 0} onClick={handleDelete}/></a>&nbsp;
              <a><Button variant='primary' text='Add Profile' onClick={handleClick}/></a>

                */

              }
            </div>

          </div>
        );
      };
  
class ProfilesList extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        columnDefinitions: COLUMN_DEFINITIONS,
        selectedDistributions: [],
        profiles: [],
        filteringText: '',
        pageSize: 30,
        contentSelectorOptions: CONTENT_SELECTOR_OPTIONS,
        wrapLines: false
      };
      this.onPaginationChange = this.onPaginationChange.bind(this);
    }
  

    loadAmbassadorProfiles(){
      
      
      Auth.currentAuthenticatedUser()
      .then(user => {
        console.log(user);
        

        API.graphql(graphqlOperation(getExplorerProfile,{"cognito_user":user.username}))
        .then(res => { 
          console.log("Ambassador res ", res )
    
           // filter the region for this ambassador
           var filter = {}; // empty for global
           var filterLabel = "GLOBAL"; // empty for global
           
            if (res.data.getExplorerProfile.admin_region===undefined){  this.loadRegionalAmbassadorProfiles(undefined, filterLabel);}
            else
            if (
              res.data.getExplorerProfile.admin_region!==null && 
               res.data.getExplorerProfile.admin_region!=="" && 
               res.data.getExplorerProfile.admin_region!=="GLOBAL"
              ){
                filter = {"filter": {"region": {"eq": res.data.getExplorerProfile.admin_region}}};
                filterLabel=res.data.getExplorerProfile.admin_region;
                this.loadRegionalAmbassadorProfiles(filter, filterLabel);
              }
  
        }).catch(error => {
          console.log("Couldn't establish ambassador ", error);
          this.setState({ error: "Unable to load profile." , ready: true});

          var filter =  {};
          this.loadRegionalAmbassadorProfiles(filter , "GLOBAL");
        });
      }).catch(err => console.log(err)) // end authentication
    }

    loadRegionalAmbassadorProfiles(filter,filterLabel ){
      var thisFilter={};
      if (filter!==undefined){thisFilter = filter}
      console.log("sending filter : "  + thisFilter);
      thisFilter.limit=5000;
      API.graphql(graphqlOperation(listExplorerProfiles,thisFilter))
      .then(res => { 
        console.log("Something went right: ", res )
        this.setState({ profiles: res.data.listExplorerProfiles.items , regionFilter: filterLabel})
      }).catch(error => console.error("Something went wrong: ", error));
    }

    componentWillMount(){
      this.loadAmbassadorProfiles();
    } // end will mount


    componentDidMount() {
      loadWidths().then(columnDefinitions => {
        this.setState({
          columnDefinitions
        });
      });

      if (this.props.filterText!==undefined && this.props.filterText!==""){
        this.setState({filteringText: this.props.filterText});
      }
    }
  
    onFilteringChange({ detail: { filteringText } }) {
      this.setState({
        filteringText
      });
    }
  
    onPaginationChange({ detail: { pageSize } }) {
      this.setState({
        pageSize
      });
    }
  
    onContentSelectionChange({ detail}) {
      const contentSelection = detail.contentSelection;
      const currentContentSelectorOptionGroup = this.state.contentSelectorOptions[0];
      this.setState({
        contentSelectorOptions: [{
          label: currentContentSelectorOptionGroup.label,
          options: currentContentSelectorOptionGroup.options.map(opt => ({
            id: opt.id,
            label: opt.label,
            editable: opt.editable,
            visible: contentSelection.indexOf(opt.id) !== -1,
          }))
        }]
      });
    }
  
    onWrapLinesChange({ detail }) {
      this.setState({
        wrapLines: detail.value
      });
    }
  
    headerCounter(selectedDistributions, distributions) {
      return selectedDistributions.length
        ? `(${selectedDistributions.length}/${distributions.length})`
        : `(${distributions.length})`;
    }
  
    clearFilter() {
      this.setState({
        filteringText: ''
      });
    }
  
    deleteSelections(){
        alert(this.state.selectedDistributions);
    }


    render() {
      return (
        <div>

        <span>
          <Link to='/myActivities'><Button text='My Activities'/></Link>
          &nbsp; 
          <Link to='/allActivities'><Button text='All Activities'/></Link>
          <span style={floatRightStyle}>
            &nbsp;
         </span>
          <p/>
        </span>
       
        <Table
          columnDefinitions={this.state.columnDefinitions}
          items={this.state.profiles}
          wrapLines={true}
          stickyHeader={true}
          resizableColumns={true}
          onColumnWidthsChange={saveWidths}
          header={(
            <TableHeader
              selectedDistributions={this.state.selectedDistributions}
              counter={this.headerCounter(this.state.selectedDistributions, this.state.profiles)}
              watcher={this.props.watcher}
              table={this}
              region={this.state.regionFilter}
            />
          )}
          
          onWrapLinesChange={this.onWrapLinesChange.bind(this)}
        >

      <TableFiltering
          filteringLabel='Search Profiles'
          filteringPlaceholder='Search Profiles'
          filteringText={this.state.filteringText}
          onFilteringChange={this.onFilteringChange.bind(this)}
          filteringCountTextFunction={getFilterCounterText}
        />
          <TablePagination
            onPaginationChange={this.onPaginationChange}
            labels={paginationLabels}
            pageSize={this.state.pageSize}
          />
          <TableSorting sortableColumns={SORTABLE_COLUMNS}/>
          <TableSelection
            selectionType={"single"}
            selectedItems={this.state.selectedDistributions}
            labels={distributionSelectionLabels}
            onSelectionChange={evt => this.setState({selectedDistributions: evt.detail.selectedItems})}
          />
        </Table>
        </div>
      )
    }
  }

  export default ProfilesList;