import React, { Component } from 'react';
import { API, graphqlOperation } from 'aws-amplify'

import * as queries from "../graphql/queries";
import { categoryTypes } from './ActivitiesCategoryReference'
import  {contributionTypes } from './ActivitiesTypeReference' 


import * as JWT from 'jwt-decode';


import '@amzn/awsui-components-react/index.css';
import '../styles/form.scss';
import { Auth } from 'aws-amplify'

import {
  AppLayout
} from '@amzn/awsui-components-react';
import { appLayoutLabels } from '../common/labels';

import AllActivitiesTable from "./AllActivitiesTable"


class AllActivitiesList extends Component {
  constructor(props) {
    super(props);

    //Set 'All' options as default for Type and category

    let allType = {label: "All", id: "All", score: 0, link: true, desc: "<div> All contributions</div>" };
    let allCategory = {label: "All",id: "All"};

    categoryTypes.unshift(allCategory);
    contributionTypes.unshift(allType);

    this.state = {
      contentOrigins: [], toolsIndex: 0, toolsOpen: false, showCreateForm: false,
      selectedCategory: categoryTypes[0].id,
      selectedType: contributionTypes[0].id,
      lastThirtyDaysSelected: false,
      currentUser: { "email": "nobody", "administratior": false },
      userProfile: {},
      contributions: [],
      flash_message: null,
      noprofile: true,
      loading: true,
    }
  }

  selectCategory(category) {
    if (this.state.selectedCategory !== category) {
      this.setState({ selectedCategory: category });
      this.recursiveActivitiesBycategory("GLOBAL");      
    }
  }

  selectType(type) {
    if (this.state.selectedType !== type) {
      this.setState({ selectedType: type });
      this.recursiveActivitiesBycategory("GLOBAL");
    }
  }

  selectDate(flag) {
    this.setState({lastThirtyDaysSelected: flag}, () => { 
      this.recursiveActivitiesBycategory("GLOBAL");
    });
  }

  componentDidMount() {
    let parent = this;
    Auth.currentAuthenticatedUser()
      .then(user => {
        var token = JWT(user.signInUserSession.accessToken.jwtToken);
        var isAdministrator = false;
        if (token['cognito:groups'] !== undefined && token['cognito:groups'][0] === "ExplorersAdministrators") { isAdministrator = true; }
        user.attributes.administrator = isAdministrator;
        parent.setState({ currentUser: user.attributes });

        var filter = {};
        this.recursiveActivitiesBycategory(filter, "GLOBAL");

      }).catch(err => console.error(err)) // end authentication
  } // end will mount

  componentWillUnmount() {
    // remove the 'All' from the category and type array
    categoryTypes.shift();
    contributionTypes.shift();
  }

  async recursiveActivitiesBycategory(regionFilter) {
    let that = this;
 
      const fetchPages = async (nextToken, params, context) => {
        if ( that.state.selectedType !== context.sourceState.type ) {
          console.log("Type has changed, aborting fetchPages");
          return;
        }
        if ( that.state.selectedCategory !== context.sourceState.category ) {
          console.log("Category has changed, aborting fetchPages");
          return;
        }

        let queryName = context.queryName;
        let query = queries[queryName[0].toLowerCase() + queryName.slice(1)];
        if (nextToken !== "") { params.nextToken = nextToken }
        return API.graphql(graphqlOperation(query, params))
          .then(res => {        
            const responseResult = res.data[queryName];
            fetchedContributions = fetchedContributions.concat(responseResult.items);
            if (null !== responseResult.nextToken) {
              fetchPages(responseResult.nextToken, params, context);
            }
            this.setState({ contributions: fetchedContributions, regionFilter: regionFilter, loading: false })
          }).catch(error => console.error("Something went wrong: ", error));

      }

      let fetchedContributions = [];
      this.setState({ loading: true })
      let params = { 
        limit: 200,
        sortDirection: "DESC"
      }
      let queryName = '';
      if (this.state.selectedCategory === 'All' && this.state.selectedType === 'All') {
        // No filters applied
        queryName = 'ActivitiesByStatusAndDate';
        params.status = 'APPROVED' //All items are APPROVED, this is just a way to use a secondary index with custom sort.
      } else if (this.state.selectedCategory === 'All' && this.state.selectedType !== 'All') {
        // Filter only by type
        queryName = 'ActivitiesByTypeAndDate';
        params.output_type = this.state.selectedType;
      } else if (this.state.selectedCategory !== 'All' && this.state.selectedType === 'All') {
        // Filter only by category
        queryName = 'ActivitiesByCategoryAndDate';
        params.category = this.state.selectedCategory;
      } else {
        // Filter both by category and type
        queryName = 'ActivitiesByCategoryAndDate';
        params.category = this.state.selectedCategory;
        // Use the query by category and add a filter for type, unless a query by two fields was possible.
        params.filter = {
          output_type : {eq:this.state.selectedType}
        };
      }
      await fetchPages("", params, {queryName, sourceState: { category: this.state.selectedCategory, type: this.state.selectedType} });
  }


  setFlashMessage(flash) {
    this.setState({ flash_message: flash })
  }

  render() {

    return (
      <div className='awsui'>

        <AppLayout
          contentType='table'
          notifications={this.state.flash_message}
          content={<AllActivitiesTable selectedCategory={this.state.selectedCategory} selectedType={this.state.selectedType} watcher={this} disableAdd={this.state.noprofile} loading={this.state.loading} isAdmin={this.state.currentUser.administrator} distributions={this.state.contributions} currentUser={this.state.currentUser} updateTools={() => this.setState({ toolsOpen: true })} />}
          navigationHide
          toolsHide
          labels={appLayoutLabels}
        />

      </div>

    );
  }

}

export default AllActivitiesList;
