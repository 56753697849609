import React from "react";
import { Authenticator , Greetings , VerifyContact , SignIn , ConfirmSignIn , ForgotPassword , TOTPSetup, Loading} from "aws-amplify-react";
import { NavItem, NavBar } from 'aws-amplify-react'

import { Auth } from 'aws-amplify'
import Activities from "./activities/Activities";
import AllActivitiesList from "./activities/AllActivitiesList";

import ViewProfile from "./profiles/ViewProfile";
import EditProfile from "./profiles/EditProfile";
import Profiles from "./profiles/Profiles";

import AmbassadorGreetings from "./AppGreeting";
import PageTracker from "./PageTracker";

import { API, graphqlOperation } from 'aws-amplify'

import { getExplorerProfile } from "./graphql/queries";

import {
  Route,
  HashRouter
} from "react-router-dom";

const globalSignupConfig = {
  hiddenDefaults: ["phone_number" , "email"],
  signUpDisplayed: false, 
  signUpFields: [
    { label: "Name", key: "name", required: true, type: "string" , displayOrder: 1},
    { label: "Email", key: "username", type: "string" , displayOrder: 1 }
  ]
};

declare function cwr(operation: string, payload: any): void;

class App extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = { 
      signedIn: false
    }
  }

  /* Records errors in RUM, otherwise React swallows them */
  componentDidCatch(error, info) {
    if (typeof cwr !== 'undefined') {
      cwr('recordError', error)
    };
  };

  handleStateChange = state => {
    //console.log(state);
    if (state === 'signedIn' || state==="verifyContact") {
     // this.setState({ signedIn: true});
      let parent = this;

      Auth.currentAuthenticatedUser()
      .then(user => {
        parent.setState({ currentUser: user.attributes ,signedIn: true });
        // fetch this profile
        API.graphql(graphqlOperation(getExplorerProfile,{"cognito_user":user.attributes.sub}))
        .then(res => { 
         parent.setState({ userProfile: res.data.getExplorerProfile || {}, noprofile: res.data.getAPNAmbassadorProfile==null})
        }).catch(error => {
         console.log(error);
        });
      }).catch(err => console.log(err)) // end authentication
    }
    if (state === 'signIn') {
      this.setState({ signedIn: false});
    }
  };

  render() {
    if(this.state.signedIn && this.state.currentUser && this.state.userProfile && window.location.hash.indexOf('#/editProfile/')!==0) {
      let requiredFields = {'first_name': 'First name', 'region': 'Region', 'country': 'Country', 'topicArea': 'Your topic area'};
      let missingFields = [];
      Object.keys(requiredFields).forEach(field => { if (!this.state.userProfile ||  !this.state.userProfile[field]) missingFields.push(field);});
      if (missingFields.length>0) {
          let missingFieldsText='';
          missingFields.forEach( (field, index) => {
            if (index>0) {
              if(index === missingFields.length - 1) {
                missingFieldsText += " and ";
              } else {
                missingFieldsText += ", ";
              }
            }
            missingFieldsText += '"'+requiredFields[field]+'"';
          })
          // There is probably a more react-ish way to do this, rendering the edit form with a warning on top or something like this, but this is quite simple and effective.
          alert('Your user profile is incomplete. Please complete ' + missingFieldsText +".");
          window.location.hash="#/editProfile";
      }
    }

    return (
        <HashRouter>
        <PageTracker />

        {
        (this.state.signedIn) ? null
        :
        <NavBar>
        <NavItem ><img src="/cb_logo.png" height="60px" alt=""/></NavItem>
        </NavBar>
        }

          <Authenticator
          hideDefault={true}
          onStateChange={this.handleStateChange}
          signUpConfig={globalSignupConfig} hide={[Greetings , VerifyContact]}>
              <SignIn/>
              <ConfirmSignIn/> 
              <VerifyContact/>
              <ForgotPassword/>
              <TOTPSetup/>
              <Loading/>


         </Authenticator>
       {
          (this.state.signedIn) ? 
          <div>
              <AmbassadorGreetings userProfile={this.state.userProfile} cognito_user={this.state.currentUser.sub}/>
              <Route exact path="/" component={Activities} />
              <Route path="/profiles" component={Profiles} />
              <Route path="/myActivities" component={Activities} />
              <Route path="/allActivities" component={AllActivitiesList} />
              <Route path="/viewProfile/:id" component={ViewProfile} />
              <Route path="/editProfile" component={EditProfile} />
          </div>
          :
          <div style={{textAlign: 'center', color: 'grey'}}>
             Registration is temporarily closed for new users.
          </div>
        }
        </HashRouter>
    );
  }
}

export default App;