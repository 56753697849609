import React from 'react';

import { Icon } from '@amzn/awsui-components-react';

export const COLUMN_DEFINITIONS = [
  {
    id: 'name',
    header: () => 'Name',
    cell: item => <span>{item.first_name} {item.last_name}</span>,
    minWidth: '200px',
    allowLineWrap: true
  },
    {
        id: 'email',
        header: () => 'Email',
        cell: item => item.email,
        minWidth: '200px',
        allowLineWrap: true
      },
  {
    id: 'region',
    cell: item => item.region,
    header: () => 'Region',
    minWidth: '100px',
    allowLineWrap: true
  },
  {
    id: 'country',
    header: () => 'Country',
    cell: item => item.country,
    minWidth: '100px',
    allowLineWrap: true
  },
  {
    id: 'status',
    header: () => 'Status',
    cell: item =>  <span>
    {("AMBASSADOR" === item.program_status) ? 
    <span className="awsui-util-status-positive"><Icon name="status-positive" /> Ambassador</span>
    : 
    <span className="awsui-util-status-negative">{item.program_status}</span>
    }</span>,
    allowLineWrap: false
  }
];

export const SORTABLE_COLUMNS = [
  { id: 'name', field: 'first_name' },
  { id: 'email', field: 'email' },
  { id: 'region', field: 'region' },
  { id: 'total_approved_contributions', field: 'total_approved_contributions' },
  { id: 'total_score', field: 'total_score' },
  { id: 'origin', field: 'origin' },
  { id: 'status', field: 'status' },
  { id: 'state', field: 'state' },
  { id: 'logging', field: 'logging' }
];

export const CONTENT_SELECTOR_OPTIONS = [
  {
    label: 'Main distribution properties',
    options: [
      { id: 'id', label: 'Distribution ID', editable: false, visible: true },
      { id: 'domainName', label: 'Domain name', editable: true, visible: true },
      { id: 'deliveryMethod', label: 'Delivery method', editable: true, visible: true },
      { id: 'priceClass', label: 'Price class', editable: true, visible: false },
      { id: 'sslCertificate', label: 'SSL certificate', editable: true, visible: true },
      { id: 'origin', label: 'Origin', editable: true, visible: false },
      { id: 'status', label: 'Status', editable: true, visible: true },
      { id: 'state', label: 'State', editable: true, visible: true },
      { id: 'logging', label: 'Logging', editable: true, visible: false }
    ]
  }
];

export const PAGE_SELECTOR_OPTIONS = [
  { value: 10, label: '10 Distributions' },
  { value: 30, label: '30 Distributions' },
  { value: 50, label: '50 Distributions' }
];

export const PROPERTY_FILTERING_OPTIONS = [
  { propertyLabel: 'Domain name', propertyKey: 'domainName', groupValuesLabel: 'Domain name values', values: [] },
  { propertyLabel: 'Delivery method', propertyKey: 'deliveryMethod', groupValuesLabel: 'Delivery method values', values: [] },
  { propertyLabel: 'Price class', propertyKey: 'priceClass', groupValuesLabel: 'Price class values', values: [] },
  { propertyLabel: 'Origin', propertyKey: 'origin', groupValuesLabel: 'Origin values', values: [] },
  { propertyLabel: 'Status', propertyKey: 'status', groupValuesLabel: 'Status values', values: [] },
  { propertyLabel: 'State', propertyKey: 'state', groupValuesLabel: 'State values', values: [] },
  { propertyLabel: 'Logging', propertyKey: 'logging', groupValuesLabel: 'Logging values', values: [] },
  {
    propertyLabel: 'SSL certificate',
    propertyKey: 'sslCertificate',
    groupValuesLabel: 'SSL certificate values',
    values: []
  }
];

export const CUSTOM_PREFERENCE_OPTIONS = [{ value: 'table', label: 'Table' }, { value: 'cards', label: 'Cards' }];

export const PROPERTY_FILTERING_I18N_CONSTANTS = {
  placeholder: 'Filter distributions by property or value',
  operationAndText: 'and',
  operationNotAndText: 'and not',
  operationOrText: 'or',
  operationNotOrText: 'or not',
  clearFiltersText: 'Clear filters',
  groupPropertiesText: 'Properties',
  groupValuesText: 'Values',
  filteringEmpty: 'No results found',
  filteringLabel: 'Filter distributions',
  filteringStatusText: 'Loading'
};