export const topicOptions = [
    {
        "label": "Cloud Operations",
        "id": "MGMI" // This referred to M&G + Migration, ID cannot be changed without changing the data in DDB.
    },
    {
        "label": "Containers",
        "id": "CONT"
    },
    {
        "label": "Data",
        "id": "DATA"
    },
    {
        "label": "Dev Tools",
        "id": "DEVT"
    },
    {
        "label": "Front-End Web & Mobile",
        "id": "FEWM"
    },
    {
        "label": "Game Tech",
        "id": "GAME"
    },
    {
        "label": "Graviton/Arm",
        "id": "GARM"
    },
    {
        "label": "Machine Learning",
        "id": "ML"
    },
    {
        "label": "Network C&D",
        "id": "NECD"
    },
    {
        "label": "Security & Identity",
        "id": "SECI"
    },
    {
        "label": "Serverless",
        "id": "SRVL"
    },
    {
        "label": "Storage",
        "id": "STOR"
    }/*,
    {
        "label": "None",
        "id": "-"
    }*/
];
