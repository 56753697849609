import React, { Component } from 'react';
import { API, graphqlOperation } from 'aws-amplify'

import { listActivitys } from "../graphql/queries";
import { getExplorerProfile } from "../graphql/queries";

import '@amzn/awsui-components-react/index.css';
import '../styles/form.scss';
import { Auth } from 'aws-amplify'


import {
  AppLayout,
 Flash
} from '@amzn/awsui-components-react';
import { appLayoutLabels } from '../common/labels';

import ContributionsList from "./MyActivitiesList"
import AddContribution from "./NewActivity"
import EditContribution from "./EditActivity"


import * as JWT from 'jwt-decode';


class Achievements extends Component {
   constructor(props){
     super(props);

     this.state = { 
       contentOrigins: [], toolsIndex: 0, toolsOpen: false, showEditForm: false,
        currentUser: {"email": "nobody","administratior": false, "sub": "none"},
        userProfile: {},
        contributions: [],
        flash_message: null,
        noprofile: true,
        loading: true,
      }
   }

  getAPNContributionsForUser(){

      var filter = {"filter": {"email": {"eq": this.state.currentUser.email}}};
      filter.limit=5000;
    // fetch contributions for this user
    API.graphql(graphqlOperation(listActivitys,filter))
    .then(res => { 
      //console.log("Something went right: ", res )
      // create a sortable column from the votes
      res.data.listActivitys.items.forEach(function(item,index){
        res.data.listActivitys.items[index].total_votes=(item.votes==null) ? 0 : item.votes.length;
      });
      this.setState({ contributions: res.data.listActivitys.items})
    }).catch(error => console.error("Something went wrong: ", error));

 }

 async recursiveAPNContributionsForUser(){

  var filter = {"filter": {"email": {"eq": this.state.currentUser.email}}};
  filter.limit=300;

  
  let fetchedContributions = [];

  const fetchPages = async (nextToken, params) =>{
 
    
     if (nextToken!==""){params.nextToken=nextToken}
     await API.graphql(graphqlOperation(listActivitys , params))
     .then(res => { 
    // create a sortable column from the votes
    res.data.listActivitys.items.forEach(function(item,index){
      res.data.listActivitys.items[index].total_votes=(item.votes==null) ? 0 : item.votes.length;
    });
       fetchedContributions = fetchedContributions.concat(res.data.listActivitys.items);
       if ( null!==res.data.listActivitys.nextToken )  {
         fetchPages(res.data.listActivitys.nextToken, params);
       } else       {
         this.setState({ contributions: fetchedContributions, loading: false })
       }
     }).catch(error => console.error("Something went wrong: ", error));

   }
   await fetchPages("", filter);

}

  componentDidMount(){
  let parent = this;

  
  Auth.currentAuthenticatedUser()
  .then(user => {
    var token = JWT(user.signInUserSession.accessToken.jwtToken);
    var isAdministrator = false;
    if (token['cognito:groups']!==undefined && token['cognito:groups'][0]==="ExplorersAdministrators")
    {isAdministrator=true;}
    user.attributes.administrator=isAdministrator;
    parent.setState({ currentUser: user.attributes});

    this.recursiveAPNContributionsForUser();


   // console.log("getApnAmbassadorProfile " + user.attributes.sub);
    // fetch contributions for this user
    API.graphql(graphqlOperation(getExplorerProfile,{"cognito_user":user.attributes.sub}))
    .then(res => { 
     // console.log("Ambassador profile ", res )
    
        if (res.data.getExplorerProfile===null) {
          this.setState({ flash_message: <Flash dismissable={true} content="You must update your profile before adding items." /> , noprofile: true})
        }else{
          this.setState({ userProfile: res.data.getExplorerProfile, noprofile: false})
        }
    }).catch(error => {
      this.setState({ flash_message: <Flash dismissable={true} content="Something went wrong retrieving your profile. Log out and try again later." /> , noprofile: true})
    });


  }).catch(err => console.log(err)) // end authentication

  } // end will mount

  createClicked(){
    this.setState({showEditForm: true})
  }

  editClicked(selections){
    // console.log(selections[0]);
    this.setState({showEditForm: true, edit: true, selected: selections[0]});
  }

  cancelClicked(){
    this.setState({showEditForm: false, edit: false})
  }

  onContributionModified(message){
    this.setState({showEditForm: false});
    var filter = {"filter": {"email": {"eq": this.state.currentUser.email}}};
    filter.limit=5000;
    // fetch contributions for this user
    API.graphql(graphqlOperation(listActivitys,filter))
    .then(res => { 
      //console.log("Something went right: ", res )
      this.setState({ contributions: res.data.listActivitys.items , flash_message: <Flash dismissible={true} type="success" content={message} />})
    }).catch(error => console.error("Something went wrong: ", error));

  }


  setFlashMessage(flash){
    this.setState({ flash_message: flash})
  }

  render(){

    return (
      <div className='awsui'>

      {

        (this.state.showEditForm === true) ? 

        <span>
          {
            (this.state.edit === true) ? 
            <AppLayout
            contentType='form'  
            content={<EditContribution watcher={this} isAdmin={this.state.currentUser.administrator} success=
            {this.onContributionModified} selected={this.state.selected} userProfile={this.state.userProfile} currentUser={this.state.currentUser}/>}
            navigationHide
            toolsHide
            labels={appLayoutLabels}
          /> 
            
            
            : 
          
            <AppLayout
              contentType='form'  
              content={<AddContribution watcher={this} isAdmin={this.state.currentUser.administrator} success=
              {this.onContributionModified} userProfile={this.state.userProfile} currentUser={this.state.currentUser}/>}
              navigationHide
              toolsHide
              labels={appLayoutLabels}
            />


          }
        </span>
        
      
       :
        <AppLayout
        contentType='table'
        notifications={this.state.flash_message}
  
        content={<ContributionsList watcher={this} disableAdd={this.state.noprofile} isAdmin={this.state.currentUser.administrator} distributions={this.state.contributions} loading={this.state.loading} currentUser={this.state.currentUser} updateTools={() => this.setState({ toolsOpen: true })} />}
        navigationHide
        toolsHide
        labels={appLayoutLabels}
        />
    
      
      /* end  if show  create form */
      }


      

      </div>

    );
  }

}

export default Achievements;
